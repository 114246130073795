const state ={
    messages:[],

};

const getters ={
    getMessages: ()=> {
        return state.messages;
    },
};

const mutations ={
    SET_MESSAGES: function (state, messages){
        messages.forEach(function(item, index, array){
            state.messages.unshift(item)
        });
        //state.messages = messages;
    },
    ADD_MESSAGE: function (state, message) {
        state.messages.push(message)
    },
    UPDATE_MESSAGES: function (state, messages) {
        state.messages = [];
        messages.forEach(function(item, index, array){
            state.messages.unshift(item)
        });
    }
};

const actions ={
    addMessage ({commit, dispatch, getters}, message) {
        return new Promise((resolve, reject) => {
            axios.post('/messages/send', message)
                .then((responses)=>{
                    commit('ADD_MESSAGE', responses.data.data);
                    resolve(responses.data.data)
                })
                .catch((errors)=>{

                })
        })
    },
    loadMessages({commit, dispatch, getters}, data){
        return new Promise((resolve, reject)=>{
            axios.get('/messages/users/'+data.currentUser+'?page='+data.current_page)
                .then((responses) => {
                    commit('SET_MESSAGES', responses.data.data)
                    resolve(responses.data);
                })
                .catch((errors) => {

                })
        })
    },
    updateMessages({commit, dispatch, getters}, data){
        //commit('UPDATE_MESSAGES')
        return new Promise((resolve, reject)=>{
            axios.get('/messages/users/'+data.currentUser+'?page='+data.current_page)
                .then((responses) => {
                    commit('UPDATE_MESSAGES', responses.data.data)
                    resolve(responses.data);
                })
                .catch((errors) => {

                })
        })
        //this.loadMessages({commit, dispatch, getters}, data);
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
