<template>
    <v-card>
        <v-card-title> Список задач </v-card-title>
        <v-card-text>
            <div class="form-group">
                <v-menu offset-y>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            +
                        </v-btn>
                    </template>
                    <v-list>
                        <v-list-item
                            v-for="(action, index) in actions"
                            :key="index"
                        >
                            <v-list-item-title><router-link :to="{ name: action.name, params: { content_id: 0, course_id: course_id }}">{{ action.title }}</router-link></v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>
            <v-data-table
                :headers="headers"
                :items="contents"
                :options.sync="options"
                :server-items-length="totalcontent"
                :loading="loading"
                class="elevation-1"
            >
                <template v-slot:body="props">
                    <draggable
                        :list="props.items"
                        tag="tbody"
                        @end="onDragEnd"
                    >
                        <tr
                            v-for="(content, index) in props.items"
                            :key="index"
                        >
                            <td>
                                <v-icon
                                    small
                                    class="page__grab-icon"
                                >
                                    mdi-arrow-all
                                </v-icon>
                            </td>
                            <td> {{content.title}}</td>
                            <td> {{getComponentByType(content.content_type).type_name}}</td>
                            <td> {{content.position}}
                                <v-icon
                                    small
                                    @click="edit(content)"
                                >
                                    mdi-pencil
                                </v-icon>
                            </td>
                        </tr>
                    </draggable>
                <!--<template v-slot:item.content="{ item }">
                    <router-link :to="{ name: getComponentByType(item.content_type).name, params: { content_id: item.content_id, course_id: course_id }}">{{item.title}}</router-link>
                </template>
                <template v-slot:item.content_type="{ item }">
                    {{getComponentByType(item.content_type).type_name}}
                </template>-->
                <!--<template v-slot:item.position="{ item }">
                    <button class="btn btn-small btn-primary" @click="up(item)"  v-if="item.position>1">выше</button>
                    <button class="btn btn-small btn-primary" @click="down(item)" >ниже</button>
                </template>-->
                </template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import draggable from 'vuedraggable'
    export default {
        name: 'CourseContentsComponent',
        props:{
            course_id:{
                type: [String, Number]
            }
        },
        data () {
            return {
                actions: [
                    { name: 'task_edit' , title: 'Добавить задачу', type: 'App\\Task', type_name: 'Задача' },
                    { name: 'article_edit', title: 'Добавить статью', type: 'App\\Article',type_name:  'Статья' },
                    { name: 'new_edit', title: 'Добавить новость', type: 'App\\New', type_name: 'Новость' },
                ],
                totalcontent: 0,
                contents: [],
                loading: true,
                options: {},
                headers: [
                    {
                        text: 'Название задачи',
                        align: 'start',
                        sortable: false,
                        value: 'content',
                    },
                    { text: 'Тип задачи', value: 'content_type' },
                    { text: 'Последняя активность', value: 'position' },

                ],
            }
        },
        watch: {
            options: {
                handler () {
                    this.getDataFromApi()
                },
                deep: true,
            },
        },
        mounted () {
            this.getDataFromApi()
        },
        created(){
        },
        components: {
            draggable,
        },
        methods: {
            onDragEnd(event){
                let item = this.contents[event.oldIndex];
                let newPosition = this.contents[event.newIndex].position;
                //if( item.position === newPosition ) return;
                axios.post('/api/content/'+item.id+'/up', {
                    position: newPosition
                });
            },
            edit(content){
                this.$router.push({ name: this.getComponentByType(content.content_type).name, params: { content_id: content.content_id, course_id: this.course_id} })
                //{ name: getComponentByType(content.content_type).name, params: { content_id: content.content_id, course_id: course_id }}
            },
            up(item){
                let position = item.position-1;
                axios.post('/api/content/'+item.id+'/up', {
                        position: position
                    })
                    .then(response => {
                        this.getDataFromApi()
                        }
                    );
            },
            down(item){
                let position = item.position+1;
                axios.post('/api/content/'+item.id+'/up', {
                    position: position
                })
                    .then(response => {
                            this.getDataFromApi()
                        }
                    );
            },
            getComponentByType(type){
                for (let action in this.actions){
                    if(this.actions[action].type == type){
                        return this.actions[action];
                    }
                }
            },
            getDataFromApi () {
                this.loading = true
                this.fakeApiCall().then(data => {
                    this.contents = data.data.data
                    this.totalcontent = data.data.total
                    this.loading = false
                })
            },
            fakeApiCall () {
                return new Promise((resolve, reject) => {
                    const { sortBy, sortDesc, page, itemsPerPage } = this.options

                    let items = axios.get('/api/contents/json', {params: {
                            page: page,
                            limit: itemsPerPage,
                            course_id: this.course_id,
                            sort: sortBy,
                            desc: sortDesc,
                        }})
                        .then(response => {
                                resolve(response)
                                this.loading = false
                            }
                        );
                    //const total = items.length
                })
            },
        },
    }
</script>
