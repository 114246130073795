<!--<template>
    <select :value="value" @change = "$emit('input', $event.target.value)" class="form-control">{{value}}
        <option v-for="category in getCategories" :value="category.id">{{ category.title}}</option>
    </select>
</template>

<script>
import {mapActions, mapState, mapGetters} from 'vuex'

export default {
    name: 'CategoriesSelect',
    props: {
        value: {
            type: Number,
            default: null
        },
        withOutCategories:{
            type: Array,
            default:  () => []
        }
    },
    computed:{
        ...mapGetters('categories', {
            getCategoriesWithOut: 'getCategoriesWithOut'
        }),
        getCategories() {
            return this.getCategoriesWithOut(this.withOutCategories)
        }
    },
    data: function () {
        return {
            category: null,
        }
    },
    methods:{
        ...mapActions('categories', {
//                loadCategories: 'loadCategories',
            }
        ),
    },
    mounted(){
  //      this.loadCategories();
    },
}
</script>-->
<template>
    <div>
    <v-autocomplete
        v-model="category"
        :items="categories"
        :loading="isLoading"
        :search-input.sync="search"
        color="white"
        hide-no-data
        hide-selected
        item-text="title"
        item-value="id"
        label="Parent category"
        placeholder="Start typing to Search"
        return-object
        @change = "$emit('input', category.id)"
    ></v-autocomplete>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: 'CategoriesSelect',
    props: {
        value: {
            type: Number,
        },
    },
    data: () => ({
        titleLimit: 10,
        categories: [],
        isLoading: false,
        category: null,
        search: null,
    }),
    methods:{
        ...mapActions('categories', {
            getCategoriesById: 'getCategoriesById'
        }),
    },
    watch: {
        value: {
            handler (newVal, oldVal){
                    if(newVal){
                        this.getCategoriesById(newVal).then(data => {
                            this.category = data
                            this.categories = [this.category]
                        })
                    }else{
                        this.category = null
                    }
            },
            immediate: true
        },
        search (newVal, oldVal) {
            if(!newVal) return;

            this.isLoading = true
            axios.get('/api/categories/'+newVal)
                .then(res => {
                    const { count, categories } = res.data
                    this.count = count
                    this.categories = categories
                })
                .catch(err => {
                })
                .finally(() => (this.isLoading = false))
        },
    },
}
</script>
