<template>
    <div>
        <v-card>
            <v-card-title>
                <span v-if="task.id">Edit {{task.title}}</span>
                <span v-else>Create</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-alert dense outlined type="error" v-if="errors">
                        <ul>
                            <li v-for = "error in errors">{{error[0]}}</li>
                        </ul>
                    </v-alert>
                    <v-col cols="12" sm="12">
                        <categories-select v-model.number="task.info.category_id" v-if="isEdit"></categories-select>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-select :items="languages"
                                  label="язык"
                                  item-text="name"
                                  item-value="id"
                                  v-model="task.languages"
                                  multiple
                        ></v-select>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-text-field v-model="task.info.title" label="Заголовок" v-if="isEdit"></v-text-field>
                        <div v-else>{{task.info.title}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <vue-simplemde v-model="task.info.description" :configs="mdeConfig" ref="markdownEditor" v-if="isEdit"/>
                        <div v-else>{{task.info.description}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-checkbox v-model="task.info.published" label="published" :disabled="!isEdit"></v-checkbox>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-text-field v-model="task.type" label="Тип задачи" :disabled="!isEdit"></v-text-field>
                        <div :disabled="isEdit">{{task.type}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <vue-simplemde v-model="task.content" :configs="mdeConfig" ref="markdownEditor" v-if="isEdit" />
                        <div v-else>{{task.content}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <vue-simplemde v-model="task.template" :configs="mdeConfig" ref="markdownEditor" v-if="isEdit" />
                        <div v-else>{{task.template}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <!--<v-text-field v-model="task.level" label="Уровень задачи" :disabled="!isEdit"></v-text-field>-->
                        <star-rating v-model:rating="task.level"></star-rating>
                        <div :disabled="isEdit">{{task.level}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <v-text-field v-model="task.points" label="Количество баллов" :disabled="!isEdit"></v-text-field>
                        <div :disabled="isEdit">{{task.points}}</div>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <files-component type="Image" path-save="image" :data-files='task.files' @changed="task.files = $event" :read-only="!isEdit" :multiple="false" :model='model_task'></files-component>
                    </v-col>
                    <v-col cols="12" sm="12">
                        <task-data-component v-model="task.data" :is-edit="isEdit"></task-data-component>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn color="blue darken-1" text @click="close()">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="save()"  v-if="isEdit">Save</v-btn>
                <v-btn color="blue darken-1" text @click="change()"  v-if="!isEdit">Change</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>
<script>
    import VueSimplemde from 'vue-simplemde';
    import FilesComponent from '../files/FilesComponent';
    import CategoriesSelect from "../category/CategoriesSelect";
    import {mapActions, mapGetters} from 'vuex';
    import TaskDataComponent from '../tasks/TaskDataComponent'
    import StarRating from 'vue-star-rating'
    export default {
        name: "EditTasksComponent",
        props: {
            task_id: {
                type: Number,
                default: null
            }
        },
        data: function () {
            return {
                isEdit: true,
                errors:null,
                task:{
                    info: {
                        category_id: null,
                        title: null,
                    },
                    files: [],
                    content: null,
                    type:null,
                    template: null,
                    points: null,
                    data:[],
                    languages: []
                },
                mdeConfig: {
                    spellChecker: false
                }
            }
        },
        created: function(){
            this.loadLanguages().then((response)=>{
                this.languages = response
            }).catch((errors)=>{
                this.errors = errors;
            })
            if (this.task_id){
                this.getTask(this.task_id).then((response)=>{
                    this.task = response
                }). catch((error)=>{
                    this.errors = error
                })
            }
        },
        methods:{
            ...mapGetters('categories',['findByParent']),
            ...mapActions('tasks', {saveTask: 'saveTask', getTask:'getTask'}),
            ...mapActions('mlanguages', {
                loadLanguages: 'load'
            }),
            change(){
                this.isEdit = true;
            },
            close(){
                //this.$emit('hideArticle', null);
                this.$router.push({name: 'tasks'})
            },
            save() {
               this.saveTask(this.task).then((response)=>{
                   this.task = response;
                   this.isEdit = false;
                   this.errors = null;
               }).catch((errors)=>{
                   this.errors = errors;
               })
            }
        },
        computed:{
            categoriesTitle(){
                return '';
            },
            model_task: function(){
                if (this.task.id){
                    return '{id:'+this.task.id+',name:App\Task}';
                }
                return 'App\Task';
            },
        },
        components:{
            CategoriesSelect,
            FilesComponent,
            VueSimplemde,
            TaskDataComponent,
            StarRating
        }
    }
</script>
<style scoped>
    @import '~simplemde/dist/simplemde.min.css';
    @import '~github-markdown-css';
</style>
