const task = {
    loadTask(task_id){
        return new Promise((resolve, reject) => {
            axios.get('/api/tasks/'+task_id).
            then(response => {
                resolve(response.data)

            }).catch(function (errors) {
                reject(JSON.parse(errors.request.response));
            });
        });
    },
    saveTask(task){
        return new Promise((resolve, reject) => {
            if (task.id){
                axios.put('/api/tasks/'+task.id, task).
                then(response => {
                    resolve(response.data)
                }).catch(function (errors) {
                    reject(JSON.parse(errors.request.response));
                });
            } else {
                axios.post('/api/tasks/', task).
                then(response => {
                    resolve(response.data)
                }).catch(function (errors) {
                    reject(JSON.parse(errors.request.response));
                });
            }
        })
    }
    /*getUsersByCourse(par){
        return new Promise((resolve, reject) => {
            axios.get(`/courses/${par.course_id}/students`, {params: par.params}).
            then(response => {
                resolve(response)
            }).catch(function (errors) {
                reject(JSON.parse(errors));
            });
        })
    },
    getUsersByEmail(val){
        return new Promise((resolve, reject) => {

        })
    },
    deleteUser(params){
        return new Promise((resolve, reject) => {
            axios.delete('/courses/'+params.course+'/users/'+params.user.id).
            then(response => {
                resolve(response)
            }).catch(function (errors) {
                reject(JSON.parse(errors));
            });
        })
    },*/
}

export default task
