<template>
    <v-card>
        <v-card-title>
            Редактирование статьи
        </v-card-title>
        <v-card-text>
            <!--<div class="alert alert-danger" v-if="errors">
                <ul>
                    <li v-for = "error in errors">{{error[0]}}</li>
                </ul>
            </div>
            <div class="row">
                <div class="col-sm-8 form-group">
                    Preview
                    <files-component type="Image" path-save="image" v-bind:data-files="article.info.image" @changed="article.info.image = $event" :model="model_info" :read-only="false" :multiple="false"></files-component>
                </div>
            </div>
            <div class="row">
                <div class="col-sm-8 form-group">
                    Content Files
                    <files-component  type="Image" path-save="image" :data-files="article.files" @changed="article.files = $event" :model="model_article" :read-only="false" :multiple="true" ></files-component>
                </div>
            </div>-->
            <div v-if="course_id==null">
                <v-select
                    :items="categories"
                    label="Категория"
                    item-text="title"
                    item-value="id"
                    v-model="article.info.category_id"
                ></v-select>
            </div>
            <validation-provider v-slot="{ errors }" name="Название" rules="required|max:100">
                <v-text-field label="Название" v-model="article.info.title" :error-messages="errors"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Описание" rules="required|max:255">
                <v-textarea label="Описание" v-model="article.info.description"  :error-messages="errors"></v-textarea>
            </validation-provider>
            <v-checkbox label="Опубликовать" v-model="article.info.published"  :error-messages="errors"></v-checkbox>
            <validation-provider v-slot="{ errors }" name="Содержимое" rules="required|max:255">
                <v-textarea label="Содержимое" v-model="article.content"  :error-messages="errors"></v-textarea>
            </validation-provider>
            <v-btn color="primary" @click="save()">Сохранить</v-btn>
            <v-btn @click="cancel()">Отменить</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import VueSimplemde from 'vue-simplemde';
    import FilesComponent from '../files/FilesComponent';
    import ImageViewComponent from '../files/ImageViewComponent.vue'
    import {mapActions, mapGetters} from "vuex";
    import { ValidationProvider } from 'vee-validate';
    export default {
        name: "ArticleEditComponent",
        props: {
            article_id: {
                type: Number,
                default: null
            },
            course_id: {
                type: [Number, String],
                default: null
            }
        },
        /*computed:{
            model_info: function(){
                if (this.article.info.id){
                    return '{id:'+this.article.info.id+',name:content}';
                }
                return 'content';
            },
            model_article: function(){
                if (this.article.id){
                    return '{id:'+this.article.id+',name:App\Article}';
                }
                return 'App\Article';
            },
        },*/
        data: function () {
            return {
                categories: [],
                errors:null,
                article:{
                    id: this.article_id ? this.article_id : null,
                    info: {
                        id: null,
                        title:null,
                        category_id: null,
                        course_id: this.course_id,
                        image:[],
                        published: false,
                        description: null
                    },
                    content:null,
                    files:[]
                },
                mdeConfig: {
                    spellChecker: false
                }
            }
        },
        created: function(){
            this.loadArticle(this.content_id).then( data => {
                this.article = { ...this.article, ...data }
            })
            this.loadCategories().then((data)=>{
                    this.categories = data;
                }
            )
        },
        methods:{
            ...mapActions('categories', {loadCategories: 'loadCategories'}),
            ...mapGetters('categories',[ 'getCategoriesById']),
            ...mapActions('articleStore', ['loadArticle', 'saveArticle']),
            save() {
                this.saveArticle(this.article).then(
                    data =>{
                        this.article = data
                        this.$router.push({ name: 'contents'})
                    })
            }
            ,
            cancel(){
                this.$router.push({ name: 'contents'})
            }
        },
        components:{
            FilesComponent,
            ImageViewComponent,
            VueSimplemde,
            ValidationProvider
        }
    }
</script>
<style scoped>
    @import '~simplemde/dist/simplemde.min.css';
    @import '~github-markdown-css';
</style>
