<template>
    <div name="main">
        <img :src="image" ref="img" :width="width">
    </div>
</template>

<script>
    export default {
        name: "ImageViewComponent",
        props: {
            value: {
                type: Object,
            },
            typeImage:{
                type: String
            }
        },
        data(){
            return {
                image:null,
                width:283
            }
        },
        created(){
            //this.width = document.getElementById('img').clientWidth;

            axios.get('/images/'+this.value.attach_id)
            .then((response) => {
                let main = document.getElementsByName('main');
                let main_width = main.item(main.length-1).clientWidth;
                this.width = this.$refs.img.width < main_width ? this.$refs.img.width : main_width;
                this.image = "data:image/png;base64," + response.data.b64image
            }).catch((error) => {
                ;
            })
        }
    }
</script>

<style scoped>
    .img-sm {
        width: 46px;
        height: 46px;
    }
    .img-circle{
        border-radius: 23px;
    }
</style>
