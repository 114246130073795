<template>
    <v-card>
        <v-card-title>
            Основная информация
        </v-card-title>
        <v-card-text>
            <v-form>
                <validation-provider v-slot="{ errors }" name="Название курса" rules="required|max:100">
                    <v-text-field label="Название курса" v-model="courseItem.name" :error-messages="errors">
                    </v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="Короткое название" rules="required|max:100">
                <v-text-field label="Короткое название" v-model="courseItem.info.title" :error-messages="errors"></v-text-field>
                </validation-provider>
                <validation-provider v-slot="{ errors }" name="Описание" rules="required|max:255">
                <v-textarea label="Описание" v-model="courseItem.info.description"  :error-messages="errors"></v-textarea>
                </validation-provider>
                <v-btn color="primary" @click="save(courseItem)">Сохранить</v-btn>
                <v-btn>Отменить</v-btn>
            </v-form>
        </v-card-text>
        <!--
        <div class = "form-group row">
            <div class="alert alert-danger container" v-if="!readOnly && errors">
                <ul>
                    <li v-for="error in errors">{{error[0]}}</li>
                </ul>
            </div>
        </div>

        <div class="form-group row">
            <label for="name" class="col-md-3 col-form-label col-form-label-sm text-md-right">Название курса:</label>
            <input v-if="readOnly" class="form-control form-control-sm" readonly v-model="courseItem.name"/>
            <validation-provider rules="alpha|min:3" name="name" v-slot="{validate, errors}" v-else>
                <input id="name" ref="name" type="text" class="form-control form-control-sm"
                       v-model='courseItem.name'
                       autocomplete="name" required
                       @input="validate"
                       autofocus>
                <span>{{ errors[0] }}</span>
            </validation-provider>
        </div>
        <div class="form-group row">
            <label for='title' class="col-md-3 col-form-label col-form-label-sm text-md-right">Заголовок:</label>
            <input v-if="readOnly" class="form-control form-control-sm" readonly v-model="courseItem.info.title"/>
            <validation-provider rules="alpha|min:3" name="title" v-slot="{validate, errors}" v-else>
                <input id="title" ref="title" type="text" class="form-control form-control-sm"
                       v-model='courseItem.info.title'
                        required
                       @input="validate"
                       autofocus>
                    <span>{{ errors[0] }}</span>
            </validation-provider>
        </div>
        <div class="form-group row">
            <label for='description' class="col-md-3 col-form-label col-form-label-sm text-md-right">Описание:</label>
            <textarea v-if="readOnly" class="form-control form-control-sm" readonly v-model="courseItem.info.description"/>
            <validation-provider rules="alpha|min:3" name="description" v-slot="{validate, errors}" v-else>
                <textarea id="description" ref="title" type="text" class="form-control form-control-sm"
                       v-model='courseItem.info.description'
                       required
                       @input="validate"
                          autofocus></textarea>
                <span>{{ errors[0] }}</span>
            </validation-provider>
        </div>
        <div class="form-group row">
            <div class="offset-md-3">
                <button class="btn btn-small btn-primary" @click="save(courseItem)"  v-if="!readOnly">Сохранить</button>
                <button class="btn btn-small btn-secondary" @click="readOnly = true"  v-if="!readOnly">Отменить</button>
                <button class="btn btn-small btn-primary" @click="readOnly = false; message='';"  v-else>Редактировать</button>
            </div>
        </div>
        -->
    </v-card>
</template>

<script>
import {mapActions, mapGetters, mapState} from 'vuex'
import { ValidationProvider, extend } from 'vee-validate';

export default {
    name: "CourseBaseInfoComponent",
    props: {
        course_id: {
            type: [Number, String]
        }
    },
    data: function () {
        return {
            errors: null,
            courseItem: {
                id: null,
                name: null,
                info: {
                    title: null,
                    description: null,
                }
            },
            readOnly: false
        }
    },
    methods:{
        ...mapActions('courseStore', ['loadCourse', 'saveCourse']),
        save(){
            this.saveCourse(this.courseItem).then( data => {
                this.courseItem = data
            }).catch(errors=>{
                this.errors = errors
            })
        }
    },
    created(){
        this.loadCourse(this.course_id).then( data => {
            this.courseItem = { ...this.courseItem, ...data }
        })
    },
    components:{
        ValidationProvider,
    },
    computed:{
        imageModelHash: function(){
            if( this.courseItem ) {
                return JSON.stringify({id: this.courseItem.id, type: 'content'})
            }else{
                return 'content';
            }
        }
    }
}
</script>

<style scoped>

</style>
