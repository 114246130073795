<template>
    <v-card>
        <v-card-title>
            Редактирование задачи
        </v-card-title>
        <v-card-text>
            <div v-if="course_id==null">
                <v-select
                    :items="categories"
                    label="Категория"
                    item-text="title"
                    item-value="id"
                    v-model="task.info.category_id"
                ></v-select>
            </div>
            <validation-provider v-slot="{ errors }" name="Название" rules="required|max:100">
                <v-text-field label="Название" v-model="task.info.title" :error-messages="errors" ></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Описание" rules="required|max:255">
                <v-textarea label="Описание" v-model="task.info.description"  :error-messages="errors"></v-textarea>
            </validation-provider>
                <v-checkbox label="Опубликовать" v-model="task.info.published"  :error-messages="errors"></v-checkbox>
            <validation-provider v-slot="{ errors }" name="Тип задачи" rules="required|max:100">
                <v-text-field label="Тип задачи" v-model="task.type" :error-messages="errors"></v-text-field>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Содержимое" rules="required|max:255">
                <v-textarea label="Содержимое" v-model="task.content"  :error-messages="errors"></v-textarea>
            </validation-provider>
            <validation-provider v-slot="{ errors }" name="Решение по умолчанию" rules="required|max:255">
                <v-textarea label="Решение по умолчанию" v-model="task.template"  :error-messages="errors"></v-textarea>
            </validation-provider>
            Уровень сложности
            <v-radio-group v-model="task.level">
                <v-radio
                    v-for="n in 5"
                    :key="n"
                    :label="`${n}`"
                    :value="n"
                ></v-radio>
            </v-radio-group>
            <validation-provider v-slot="{ errors }" name="Количество баллов" rules="required|max:100">
                <v-text-field label="Количество баллов" v-model="task.points" :error-messages="errors"></v-text-field>
            </validation-provider>

            <v-btn color="primary" @click="save()">Сохранить</v-btn>
            <v-btn @click="cancel()">Отменить</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
    import VueSimplemde from 'vue-simplemde';
    import FilesComponent from '../files/FilesComponent';
    import CategoriesSelect from "../category/CategoriesSelect";
    import {mapGetters, mapActions} from 'vuex';
    import ImageViewComponent from '../files/ImageViewComponent.vue'
    import TaskDataComponent from '../tasks/TaskDataComponent'
    import { ValidationProvider } from 'vee-validate';
    export default {
        name: "TasksEditComponent",
        props: {
            content_id: {
                type: Number,
                default: null
            },
            course_id: {
                type: [Number, String],
                default: null
            }
        },
        data: function () {
            return {
                categories: [],
                errors:null,
                task:{
                    info: {
                        category_id: null,
                        title: null,
                        course_id: this.course_id,
                    },
                    files: [],
                    content: null,
                    type:null,
                    template: null,
                    points: null,
                    data:[]
                },
                mdeConfig: {
                    spellChecker: false
                }
            }
        },
        created: function(){
            this.loadTask(this.content_id).then( data => {
                this.task = { ...this.task, ...data }
            })
            this.loadCategories().then((data)=>{
                this.categories = data;
            }
            )

        },
        methods:{
            ...mapActions('categories', {loadCategories: 'loadCategories'}),
            ...mapGetters('categories',[ 'getCategoriesById']),
            ...mapActions('taskStore', ['loadTask', 'saveTask']),
            save() {
                this.saveTask(this.task).then(
                    data =>{
                        this.task = data
                        this.$router.push({ name: 'contents'})
                    })
            },
            cancel(){

                this.$router.push({ name: 'contents'})
            }
        },
        computed:{
            categoriesTitle(){
                return this.task.info.category_id ? this.getCategoriesById()(this.task.info.category_id).title:'';
            },
            model_task: function(){
                if (this.task.id){
                    return '{id:'+this.task.id+',name:App\Task}';
                }
                return 'App\Task';
            },
        },
        components:{
            CategoriesSelect,
            FilesComponent,
            ImageViewComponent,
            VueSimplemde,
            TaskDataComponent,
            ValidationProvider
        }
    }
</script>
<style scoped>
    @import '~simplemde/dist/simplemde.min.css';
    @import '~github-markdown-css';
</style>
