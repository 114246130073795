<template>
    <div>
        <v-file-input counter :multiple="multiple" show-size truncate-length="15" @change="load"></v-file-input>
    </div>
</template>
<script>
import {mapActions} from 'vuex'
    export default {
        name: "FileLoadComponent",
        props:{
            multiple: {
                type: Boolean,
                default: true,
            },
            pathSave:{
                type: String,
                default: 'file'
            },
            model:{
                type: String,
                required: true
            },
        },
        data(){
           return {
               errors:null
           }
        },
        methods:{
            ...mapActions('files', {loadFiles: 'loadFiles'}),
            load(files){
                let formData = new FormData();
                if (Array.isArray(files)){
                    for( let i = 0; i < files.length; i++ ){
                        formData.append('files[' + i + ']', files[i]);
                    }
                } else {
                    formData.append('files[0]', files);
                }

                formData.append('model', this.model);

                this.loadFiles({formData: formData, pathSave: this.pathSave, multiple: this.multiple})
                    .then((response)=>{this.errors=null;})
                    .catch((errors)=>{
                        this.errors = errors
                    })
            }
        }
    }
</script>

