<template>
    <div>
        <v-card>
            <v-card-title>СЕРТИФИКАТЫ</v-card-title>
            <v-card-text>
                <v-data-table
                :headers="headers"
                :items="attempts"
                class="elevation-1"
            >
                <template v-slot:[`item.updated_at`]="{ item }">
                    {{item.updated_at | moment('DD.MM.YYYY')}}
                </template>
            </v-data-table>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        props:{
            user:{
                type: Object
            }
        },
        data(){
            return {
                attempts:[],
                headers: [
                    {
                        text: 'Название теста',
                        align: 'start',
                        value: 'test.info.title',
                        sortable: true,
                    },
                    {
                        text: 'Уровень',
                        align: 'start',
                        value: 'test.level',
                    },
                    {
                        text: 'Время прохождения',
                        align: 'start',
                        value: 'time'
                    },
                    {
                        text: 'Количество баллов',
                        align: 'start',
                        value: 'points'
                    },
                    {
                        text: 'Дата прохождения',
                        align: 'start',
                        value: 'updated_at'
                    }
                ]
            }
        },
        name: "AttemptsComponent",
        created() {
            axios.get('/users/'+this.user.user_id+'/attempts')
                .then((responses)=>{
                    this.attempts = responses.data.data;
                })
                .catch((errors)=>{

                })
        }
    }
</script>

<style scoped>

</style>
