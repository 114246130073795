<template>
    <div>
        <file-list-component :readOnly="readOnly" :show-file-names="showFileNames" :file-view-component="fileViewComponent()" :model="model"></file-list-component>
        <file-load-component v-if="!readOnly" name="files" :path-save="pathSave" count=0 :multiple="multiple" :model="model"></file-load-component>
    </div>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex';
    import FileLoadComponent from "./FileLoadComponent.vue";
    import FileListComponent from "./FileListComponent.vue";
    import ImageViewComponent from './ImageViewComponent.vue'
    import AvatarViewComponent from './AvatarViewComponent.vue'
    import FileViewComponent from './FileViewComponent.vue'

    export default {
        name: "FilesComponent",
        props: {
            pathSave:{
                type: String,
            },
            dataFiles:{
                default: ()=>[]
            },
            readOnly: {
                type: Boolean,
                default: true,
            },
            showFileNames: {
                type: Boolean,
                default: true,
            },
            multiple: {
                type: Boolean,
                default: true,
            },
            model:{
                type: String,
                required: true
            },
            type: {
                type: String,
                required: true
            },
        },
        data() {
            return {
                mapping: [
                    {type: 'Image', 'component': ImageViewComponent},
                    {type: 'File', 'component': FileViewComponent},
                    {type: 'Avatar', 'component': AvatarViewComponent}
                ]
            }
        },
        computed: {
            ...mapGetters('files', ['getFiles']),
            files: function () {
                return this.getFiles(this.model)
            },
        },
        mounted: function(){
            this.setFiles({model: this.model, files: this.dataFiles});
        },
        watch: {
            files: {
                handler(val, old){
                    if( old.length !== 0 || val.length !== 0 ) {
                        this.$emit('changed', val);
                    }
                },
                deep: false
            },
            dataFiles: {
                handler(val, old){
                    this.setFiles({model: this.model, files: val});
                },
                deep: false
            },
            model: {
                handler(val, old){
                    this.renameModelFiles({model_old: old, model_new: val});
                },
                deep: false
            }
        },
        methods: {
            ...mapActions('files', ['setFiles', 'renameModelFiles']),
            fileViewComponent: function() {
                let curComponent = this.mapping.find((item) => {
                    return item.type === this.type;
                });
                return curComponent.component;
            },
        },
        components:{
            FileListComponent,
            FileLoadComponent,
            FileViewComponent,
            ImageViewComponent,
            AvatarViewComponent
        }
    }
</script>

<style scoped>

</style>
