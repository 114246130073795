<template>
    <ul class="nav-item">
        <tree
                :item="getRootCategory"
                @fetch-data="fetchData"
                @selected="onSelected"
        ></tree>
    </ul>
</template>

<script>
    import tree from './TreeComponent'
    import {mapActions, mapState, mapGetters} from 'vuex'

    export default {
        methods: {
            ...mapActions('category', {
                    loadCategory: 'loadCategory'
                }
            ),
            fetchData: function(item){
                let item_id = item.$props.item.id;
                window.location.pathname = '/category/'+item_id+'/content'
                /*let childs = this.categoryByParent(item_id);
                item.$emit('set-children', childs);*/
            },
            onSelected: function(item){
                this.$emit('changeCategory', item);
            }
        },
        components:{
            tree
        },
        computed:{
            ...mapState('category', {
                categories: 'all'
            }),
            ...mapGetters('category',
                ['categoryByParent','getRootCategory']
            )
        },
        created(){
            this.loadCategory();
        }
    }
</script>
<style scoped>
    .category {border: 1px black solid }
</style>
