const article = {
    loadArticle(article_id){
        return new Promise((resolve, reject) => {
            axios.get('/api/articles/'+article_id).
            then(response => {
                resolve(response.data)

            }).catch(function (errors) {
                reject(JSON.parse(errors.request.response));
            });
        });
    },
    saveArticle(article){
        return new Promise((resolve, reject) => {
            if (article.id){
                axios.put('/api/articles/'+article.id, article).
                then(response => {
                    resolve(response.data.data)
                }).catch(function (errors) {
                    reject(JSON.parse(errors.request.response));
                });
            } else {
                axios.post('/api/articles/', article).
                then(response => {
                    resolve(response.data.data)
                }).catch(function (errors) {
                    reject(JSON.parse(errors.request.response));
                });
            }
        })
    }
}

export default article
