<template>
    <div>
        <v-card>
            <v-card-title>ПРОФИЛЬ</v-card-title>
            <v-card-text>
                <v-row>
                <v-col cols="12" v-if="errors">
                    <v-alert type="error" v-for = "(error, idx) in errors" :key="idx">
                        {{error[0]}}
                    </v-alert>
                </v-col>
                <v-col cols="12" v-if="message.length">
                    <v-alert type="success">
                        Данные сохранили успешно.
                    </v-alert>
                </v-col>
                    <v-col cols="12">
                        <files-component type="Avatar" :show-file-names="false" :data-files='profile.files' @changed="profile.files = $event" :read-only="false" :multiple="false" model="{id: ${profile.id}, type: App\Profile}" path-save="image"></files-component>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="email" v-model="currentUser.email" type='text' counter readonly  />
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="profile.surname"
                            :rules="[rules.required, rules.alpha]"
                            type='text'
                            label="Фамилия"
                            counter
                            hint="Поле должно содержать больше трех символов букв"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <v-text-field
                            v-model="profile.name"
                            :rules="[rules.required, rules.alpha]"
                            type='text'
                            label="Имя"
                            counter
                            hint="Поле должно содержать больше трех символов букв"
                        ></v-text-field>
                    </v-col>


                    <v-col cols="12">
                        <v-text-field
                            v-model="profile.patronymic"
                            :rules="[rules.required, rules.alpha]"
                            type='text'
                            label="Отчество"
                            counter
                            hint="Поле должно содержать больше трех символов букв"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <vue-tel-input-vuetify v-model="profile.phone"
                                               :defaultCountry = "profile.country_iso"
                                               :onlyCountries = countries

                                               hint="Введите номер телефона" :rules=[rules.phone]
                                               v-on:country-changed="countryChanged"></vue-tel-input-vuetify>
                    </v-col>

                    <v-col col="12">
                        <v-select v-model="profile.gender" label="Пол" :items="genders" item-value="type" item-text="name"/>
                    </v-col>

                    <v-col cols="12">
                        <v-menu
                            v-model="menu"
                            :close-on-content-click="false"
                            :nudge-right="40"
                            transition="scale-transition"
                            offset-y
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dateFormatted"
                                    label="Date"
                                    hint="DD-MM-YYYY"
                                    persistent-hint
                                    prepend-icon="mdi-calendar"
                                    v-bind="attrs"
                                    @blur="date = parseDate(dateFormatted)"
                                    v-on="on"
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="date"
                                no-title
                                @input="menu1 = false"
                            ></v-date-picker>
                        </v-menu>
                        <!--<datepicker id="birth_date" v-model="profile.birth_date" readonly="false" name="birth_date" format="dd.MM.yyyy"
                                    :class="{'bg-red':bootstrapStyling, 'bg-white':!bootstrapStyling}" :bootstrap-styling="true"
                                    required></datepicker>-->
                        <!--<v-datepicker v-model="profile.birth_date" readonly="false" name="birth_date" format="dd.MM.yyyy" required></v-datepicker>-->
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Количество набранных баллов" readonly v-model="profile.points"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Место в рейтинге" readonly v-model="position"/>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field label="Последняя активность" readonly :value="profile.time_points | moment('DD.MM.YYYY')"/>
                    </v-col>
`               </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn text @click="save(profile)">Сохранить</v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
    import {mapActions, mapState, mapGetters} from 'vuex'
    import { ValidationProvider, extend } from 'vee-validate';
    import Datepicker from 'vuejs-datepicker';
    import AvatarViewComponent from '../files/AvatarViewComponent.vue'

    export default {
        name: "ProfileComponent.vue",
        props:{
            user:{
                type: Object
            }
        },
        data: function(){
            return{
                countries:['DE','UA', 'RU'],
                profile:{
                    id: null,
                    surname:null,
                    name: null,
                    firstname: null,
                    phone: null,
                    birth_date: null,
                    files: [],
                },
                menu: false,
                rules: {
                    required: value => !!value || 'Обязательно для заполнения.',
                    alpha: (v) => {
                        if (v && v.length >= 3 && /^[а-яёa-zA-Z]*$/i.test(v)) {
                            return true;
                        }

                        return 'Поле должно содержать больше трех символов букв'
                    },
                    phone: (v) => {

                        if (v && /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/i.test(v)) {
                            return true;
                        }
                        return 'Поле должно содержать номер телефона'
                    },
                },
                date: null,
                dateFormatted: null, //(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                position: null,
                genders: [],
                bootstrapStyling: true,
                errors: [],
                readOnly: true,
                message:'',
                fileViewComponent: AvatarViewComponent
            }
        },
        computed:{
            notEmpty: function () {
                return Object.keys(this.errors).length;
            },
            ...mapGetters('users', { currentUser: 'getCurrentUser'})
        },
        watch: {
            date (val) {
                this.dateFormatted = this.formatDate(this.date)
                this.profile.birth_date = this.date;
            },
        },

        methods: {
            ...mapActions('profile', ['loadProfile', 'saveProfile']),
            countryChanged(country) {
                this.profile.country_iso = country.iso2
            },
            save(profile){
                this.saveProfile(profile).then(
                    (response)=>{
                        this.profile = response.profile;
                        this.readOnly = true;
                        this.message = response.message;
                        this.date = this.profile.birth_date.substr(0,10);
                        this.errors = null
                    },
                    (response)=>{
                        this.errors = response.errors
                    }
                )
            },
            formatDate (date) {
                if (!date) return null

                const [year, month, day] = date.split('-')
                return `${day}-${month}-${year}`
            },
            parseDate (date) {
                if (!date) return null

                const [day, month, year] = date.split('-')
                return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
            },
        },
        mounted(){
            this.loadProfile(this.user.profile_id).then(
                (response)=>{
                    if (response.profile){
                        this.profile = response.profile;
                    }
                    this.date = (this.profile.birth_date) ? this.profile.birth_date.substr(0,10): new Date().toISOString().substr(0,10);
                    ;
                    this.genders = response.genders;
                    this.position = response.position;
                }
            );
        },
        components:{
            ValidationProvider  ,
            AvatarViewComponent,
            Datepicker
        }
    }
</script>

<style scoped>
    .bg-grey > div > div {
        background-color:grey;
    }

    .bg-white > div > div {
        background-color:white
    }

    .profile{
        width: 98%;
    }
    .figure {
        width: 100%;
        max-width: 250px;
        height: auto;
    }
</style>
