<template>
    <timer-component :delta="delta" v-on:timer-finish="finish"/>
</template>

<script>
    export default {
        name: 'TestComponent',
        props:{
            delta: Number,
            attempt: Number
        },
        methods: {
            finish() {
                axios.post( '/attempt/'+this.attempt+'/finish/',
                ).then(function(response){
                    let data = response.data;
                    if (data.attempt){
                        window.location.pathname = '/attempt/' + data.attempt.id + '/result/'
                    }
                }).catch(function(){
                });
            },
        },
    }
</script>
<style scoped>

</style>
