<template>
    <div>
        <a class="btn btn-light btn-sm" @click.prevent="createComment(node)" v-show="!isShow">Ответить</a>
        <!-- <br> -->
        <div v-show = "isShow">
            <div class="alert alert-warning" v-if="userNotFound">
                Чтобы оставить комментарий, Вам необходимо <a :href="linkRegister">зарегистрироваться</a> на портале или авторизоваться под своим <a :href="linkLogin">Логином</a>
            </div>
            <form v-else>
                <div class="form-group">
                    <textarea :rows="calculateCommentRows" class="form-control form-control-sm no-resize" v-model = 'comment.content' placeholder="Ваше сообщение.."></textarea>
                    <button @click.prevent = "add(comment)" class="btn btn-primary btn-sm">Сохранить</button>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import {mapActions, mapGetters} from 'vuex'
    export default {
        name: "CommentsAddComponent",
        props:{
            node: {
                type: Object,
                default: () => {id: null}
            },
            modelName: {
                type: String
            },
            modelId:{
                type: Number
            }
        },
        data(){
            return {
                comment: {
                    content: '',
                    parent_id: 0
                },
                isShow: false,
                content: ''
            }
        },
        mounted: function(){
            if( !this.node.id ){
               this.createComment(this.node);
            }
        },
        computed:{
            calculateCommentRows(){
                let comment = this.comment.content ? this.comment.content : '';
                let lines = comment.split("\n").length;
                return lines > 0 ? lines : 1;
            },
            userNotFound() {
                return this.isEmptyUser();
            },
            linkRegister(){
                return '/register?return='+window.location.pathname;
            },
            linkLogin(){
                return '/login?return='+window.location.pathname;
            }

        },
        methods: {
            ...mapGetters('users', ['isEmptyUser']),
            ...mapActions('comments',['addComment']),
            createComment: function (comment) {
                this.isShow = true;

                let commentId = (comment && comment.id) ? comment.id : null;
                this.comment = {
                    parent_id: commentId,
                    model_name: this.modelName,
                    model_id: this.modelId
                };
            },
            add: function (comment) {
                this.addComment(comment).then((response)=>{
                    //this.comment = null;
                    //this.createComment();
                    //this.content = null;
                    this.$emit('add-comment', response);
                    this.isShow = false;
                })
            }
        }
    }
</script>

<style scoped>
  .no-resize {
    resize: none;
  }
</style>
