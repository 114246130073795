<template>
    <div>
    <v-card>
        <v-card-title>СМЕНИТЬ ПАРОЛЬ</v-card-title>
        <v-card-text>
            `<v-row>
            <v-col cols="12" v-if="errors">
                <v-alert type="error" v-for = "error in errors" :key="error">
                    {{error[0]}}
                </v-alert>
            </v-col>
            <v-col cols="12" class="alert alert-success" v-if="success">
                <v-alert type="success">
                    Данные сохранили успешно.
                </v-alert>
            </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="passwords.current_password"
                        :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show1 ? 'text' : 'password'"
                        label="Текущий пароль"
                        hint="Поле должно содержать 8 символов"
                        counter
                        @click:append="show1 = !show1"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="passwords.new_password"
                        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show2 ? 'text' : 'password'"
                        label="Новый пароль"
                        hint="Поле должно содержать 8 символов"
                        counter
                        @click:append="show2 = !show2"
                    ></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-text-field
                        v-model="passwords.new_confirm_password"
                        :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="[rules.required, rules.min]"
                        :type="show3 ? 'text' : 'password'"
                        label="Подтвердить пароль"
                        hint="Поле должно содержать 8 символов"
                        counter
                        @click:append="show3 = !show3"
                    ></v-text-field>
                </v-col>
        </v-row>
        </v-card-text>
        <v-card-actions>
            <v-btn text @click="save()">Сохранить</v-btn>
        </v-card-actions>
    </v-card>
    </div>
</template>

<script>
    export default {
        name: "passwordComponent",
        data: function(){
            return{
                show1: false,
                show2: false,
                show3: false,
                rules: {
                    required: value => !!value || 'Обязательно для заполнения.',
                    min: (v) => {
                        return v && v.length >= 8 || 'Введите больше 8 символов'
                    },
                },
                passwords:{
                    current_password: null,
                    new_password:null,
                    new_confirm_password: null,
                },
                message: '',
                bootstrapStyling: true,
                readOnly: true,
                errors:null,
                success:false
            }
        },
        methods: {
            save(){
                axios.post('/change-password',this.passwords)
                    .then((response)=>{
                        this.success = true;
                        this.readOnly = true;
                        this.errors = null;
                    })
                    .catch((errors) => {
                        this.success = false;
                        this.errors = JSON.parse(errors.request.response).errors;
                    })
            }
        }
    }
</script>

<style scoped>
    .bg-grey > div > div {
        background-color:grey;
    }

    .bg-white > div > div {
        background-color:white
    }

    .profile{
        width: 98%;
    }
    .figure {
        width: 100%;
        max-width: 250px;
        height: auto;
    }
</style>
