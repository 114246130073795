<template>
    <div class="container">
        <div class="row justify-content-center">
            <div v-infinite-scroll="loadMore" infinite-scroll-disabled="busy" infinite-scroll-distance="limit" class="col">
                <template v-for="group in groups_articles">
                    <div class="card-deck pb-3">
                        <div class="card " style="max-width: 20rem;" v-for="(article, index) in group">
                            <image-view-component v-if="article.info.image" :value="article.info.image"></image-view-component>
                            <div class="card-body pt-3 pb-0">
                                <h5 class="card-title"><a class="text-dark" :href="'/articles/'+article.id">{{article.info.title}}</a></h5>
                                <p class="card-text">{{article.content}}</p>
                                <p class="card-text"><small class="text-muted">Last updated 3 mins ago</small></p>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
    import ImageViewComponent from "../files/ImageViewComponent";
    export default {
        name: 'ArticleListComponent',
        components: {ImageViewComponent},
        data: function () {
            return {
                articles: [],
                busy: false,
                limit: 9,
                offset: 0,
                stop: false,
                groups_articles: []
            };
        },
        methods: {
            chunk(arr, len) {
                let i = 0,
                n = arr.length;

                while (i < n) {
                    this.groups_articles.push(arr.slice(i, i += len));
                }
                return this.groups_articles;
            },
            loadMore() {
                this.busy = (this.stop) ? false : true;
                let self = this;
                if (this.busy){
                    axios.post('/articles/load',{offset: this.offset, limit: this.limit}).then((response) => {
                        this.articles = this.articles.concat(response.data);
                        this.busy = false;
                        if (response.data.length < this.limit){
                            this.stop = true;
                        }
                        this.offset += this.limit;
                    }).catch( (err) => {
                        this.busy = false;
                    })
                }
            }
        },
        created() {
            this.loadMore();

        }
    };
</script>

<style scoped>

</style>