import courseService from '../../services/courses'

const state = {
    course: {
        id: null
    },
    users: []
};

const mutations = {
    SET_COURSE( state, course ){
        state.course = course;
    },
    SET_USERS( state, users ){
        state.users = users;

    },
    DELETE_USER(state, user){
        let index = state.users.findIndex((item)=>{
            return item.id == user.id;
        });
        state.users.splice(index, 1);
    }
};

const getters = {
};

const actions = {
    loadCourse ({commit, state}, courseId){
        return new Promise( resolve => {
            if (courseId == state.course.id){
                resolve(state.course)
            }else{
                courseService.loadCourse(courseId).then( data => {
                    commit('SET_COURSE', data)
                    resolve(data)
                })
            }
        })
    },
    storeCourse ({commit, dispatch, getters}, course){
        commit('SET_COURSE', course);
    },
    saveCourse({commit, state}, course){
        return new Promise(resolve => {
            courseService.saveCourse(course).then( data => {
                commit('SET_COURSE', data)
                resolve(data)
            }).catch(error=>{
                reject(error)
            })
        })
    },
    getUsersByCourse({commit, dispatch, getters}, par) {
        return new Promise(resolve =>{
            courseService.getUsersByCourse(par).then( data => {
                commit('SET_USERS', data.data.data)
                    resolve(data.data)
                })
        });
    },
    deleteUser({commit, dispatch, getters}, params){
        new Promise(resolve => {
            courseService.deleteUser(params).then( data => {
                commit('DELETE_USER', params.user)
                resolve(state.users)
            })
        })
    }

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
