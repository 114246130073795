<template>
    <div>
        <v-list>
            <template class="pa-4" v-for="(message, index) in messagesList" :keys="message.id">
                <v-list-item>
                    <v-list-item-avatar top>
                        <v-img :src="message.from_user.profile.avatar_path"></v-img>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-card id="card-1" outlined shaped>
                            <v-card-title class="py-1"><p :class="message.to_user.id == authuser.id? 'light-blue--text  my-1' : 'grey--text  my-1'">{{message.from_user.name}}</p></v-card-title>
                            <v-card-text class="py-1"><p class="my-1" style="white-space: normal;">{{message.body}}</p></v-card-text>
                            <v-card-subtitle class="py-1">{{message.created_at| moment('from')}}</v-card-subtitle>
                        </v-card>
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </div>
</template>

<script>
import MessageComponent from "./MessageComponent";
import {mapActions, mapGetters, mapState} from 'vuex'
export default {
    name: "MessageListComponent",
    data: function (){
        return {
            messages: [],
            currentMessage:null,
            current_page: 1,
            loaded: false
        }
    },
    props:{
        currentUser:{
            type: Number
        }
    },
    watch:{
        currentUser: function (newValue, oldValue){
            this.loaded = false;
            this.current_page = 1
            this.updateMessages({currentUser: newValue, current_page:this.current_page}).then((data)=>{
                $(window).scrollTop($(document).height());
            })
        }
    },
    computed: {
        ...mapGetters('messages', ['getMessages']),
        ...mapGetters('users', { authuser: 'getCurrentUser'}),
        messagesList(){
            return this.getMessages
        },
    },
    methods: {
        ...mapActions('messages',['loadMessages', 'updateMessages']),
        send(message){
            this.currentMessage = message;
        },
        loadProfile(profile){
            document.location.href = '/profiles/show/'+profile.id;
        },
        onScroll (e) {
            this.offsetTop = e.target.scrollTop
        },
        scroll (person) {
            window.onscroll = () => {
                let bottomOfWindow = document.documentElement.scrollTop==0 // + window.innerHeight === document.documentElement.offsetHeight;

                if (bottomOfWindow && this.loaded == false) {
                    ++this.current_page;

                    this.loadMessages({currentUser: this.currentUser, current_page:this.current_page}).then((data)=>{
                        if (data.last_page == this.current_page){
                            this.loaded = true;
                        }
                    })
                }
            };
        },
    },
    components:{
        MessageComponent
    },
    mounted() {
        this.scroll(this.person);
    }
}
</script>

<style scoped>
.img-sm {
    width: 46px;
    height: 46px;
}
.img-circle{
    border-radius: 23px;
}
 .tile {
     /*background-image: url(/images/chat-back.jpg);
     background-image30px;*/
     background-color: #E1F5FE;
 }
.tile:hover {
    /*background-image: url(/images/chat-back.jpg);
    background-image-size:30px;*/
    background-color: #E1F5FE;
}
.tile:active {
    /*background-image: url(/images/chat-back.jpg);
    background-image-size:30px;*/
    background-color: #E1F5FE;
}
</style>
