<template>
    <div class="container">
        <div class="row">
            <div class="col">
                <category></category><!--@changeCategory="selectedCategory"-->
            </div>
        </div>
    </div>
</template>

<script>
    import category from './CategoryComponent.vue'
    export default {
        /*
        methods: {
            selectedCategory(value){
                this.selectCategory =  value.id;
            }
        },
        watch: {
            filter: {
                handler(newFilter) {
                    this.$emit('filter', newFilter);
                },
                deep: true
            }
        },*/
        components:{
            category,
        },
    }
</script>
<style scoped>
    .category {border: 1px black solid }
    .p-2 {
        text-align: left;
    }
</style>