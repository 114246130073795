<template>
    <div>
        <v-navigation-drawer class="float-left">
            <v-list nav>
                <v-list-item to="base">
                    <v-list-item-icon><v-icon>mdi-file-document-outline</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Общая информация</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="students">
                    <v-list-item-icon><v-icon>mdi-account-multiple</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Студенты</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item to="contents">
                    <v-list-item-icon><v-icon>mdi-taskfreak</v-icon></v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Задания</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
        <!-- Body -->
        <v-main>
            <router-view :course_id = "$route.params.id"></router-view>
        </v-main>
    </div>
</template>

<script>
import VueRouter from "vue-router";
import CourseStudentsComponent from "./CourseStudentsComponent";
import CourseBaseInfoComponent from "./CourseBaseInfoComponent";
import CourseContentsComponent from "./CourseContentsComponent";
import TasksEditComponent from "./TasksEditComponent";
import ArticleEditComponent from "./ArticleEditComponent";
import Vue from "vue";
import {default as EditTasksComponent} from "../tasks/EditTasksComponent";
import ArticleListComponent from "../articles/ArticleListComponent";
Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes: [
        {path: '/courses/edit/:id', redirect: '/courses/edit/:id/base'},
        {path: '/courses/edit/:id/base', component: CourseBaseInfoComponent, name: 'base'},
        {path: '/courses/edit/:id/students', component: CourseStudentsComponent, name: 'students'},
        {path: '/courses/edit/:id/contents', component: CourseContentsComponent, name: 'contents'},
        {path: '/courses/edit/:id/tasks/:content_id', component: TasksEditComponent, name: 'task_edit', props: (route) => ({ content_id: Number(route.params.content_id) })},
        {path: '/courses/edit/:id/article/:content_id', component: ArticleEditComponent, name: 'article_edit', props: (route) => ({ content_id: Number(route.params.content_id) })},
    ]
})
export default {
    name: "CourseRouterComponent",
    router: router,
    props: {
    },
    created() {
    }
}
</script>
