<template>
    <div>
        <v-card>
            <v-card-title>СООБЩЕНИЯ</v-card-title>
        <v-card-text>
        <v-row>
            <v-col cols="3">
                <v-list>
                    <v-list-item-group v-model="selectedUser" color="primary">
                        <v-list-item v-for="(item, i) in users" :key="i" @click="currentUser = item.id">
                            <v-list-item-avatar>
                                <v-img :src="item.profile.avatar_path" size="48"></v-img>
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title><div class="text-overline mb-4">{{item.name}}</div></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-col>
            <v-col cols="9">
                <message-list-component :currentUser="currentUser"></message-list-component>
                <message-component :to_user_id="currentUser" ></message-component>
            </v-col>
        </v-row>
        </v-card-text>
        </v-card>
    </div>
</template>

<script>
    import MessageComponent from './MessageComponent';
    import MessageListComponent from './MessageListComponent';
    export default {
        data(){
            return {
                name:'',
                users:[],
                currentUser:null,
                selectedUser: null
            }
        },
        props:{
            user_id:{
                type: Number,
                default: 0
            }
        },
        name: "MessagerComponent",
        methods:{
            getUsers(name){
                let url = isNaN(this.user_id)?'/messages/user' : '/messages/user/'+this.user_id;
                axios.get(url)
                    .then((responses) => {
                        this.users = responses.data.data;
                        this.currentUser = isNaN(this.user_id) ? this.users[0].id : this.user_id;
                    })
                    .catch((errors) => {

                    })

            }
        },
        created() {
            this.getUsers();
        },
        components:{
            MessageComponent,
            MessageListComponent
        }
    }
</script>

<style scoped>

</style>
