const state = {
    all: []
};

const mutations = {
    SET_CATEGORIES( state, categories ){
        state.all = categories;
    },
    ADD_CATEGORY( state, category ){
        state.all.push(category);
    },
    DELETE_CATEGORY( state, category){
        let index = getters.getIndexByID(state)(category.id);
        state.all.splice(index, 1);
    }
};

const getters = {
    findByParent: state => id => {
        let filtered = state.all.filter( item => {
                return item.parentid == id
            });
        return filtered;
    },
    getCategoriesById: state => id => {
        let filtered = state.all.find(item => {
                return item.id == id
            });
        return filtered;
    },
    getRootCategory: (state, getters) => {
        return getters.getCategoriesById(1) || {};
    },
    getCategories: state => {
        return state.all
    },
    getIndexByID: state => id => {
        let index = state.all.findIndex(item => {
            return item.id == id
        });
        return index;
    },
    getCategoriesWithOut: state => without => {
        return state.all.filter(item => !without.includes(item));
    }

};

const actions = {
    loadCategories ({commit, dispatch, getters}, options) {
        return new Promise((resolve, reject) => {
            let col = (options.sortBy.length)?options.sortBy[0]:'title'
            axios.get('/api/categories/count_rows/'+options.itemsPerPage+'/page/'+options.page+'/sortBy/'+col).
            then(response => {
                commit('SET_CATEGORIES', response.data.categories);
                resolve(response.data)
            }, response => {
                ;
            });
        })
    },
    getCategoriesById({commit, dispatch, getters}, id){
        return new Promise((resolve, reject) => {
            axios.get('/api/categories/' + id).
            then(response => {
                resolve(response.data)
            }, response => {
                ;
            });
        })
    },
    deleteCategory ({commit, dispatch, getters}, category_id) {
        let category = getters.getCategoriesById(category_id);
        return new Promise((resolve, reject) => {
            axios.delete('/api/categories/'+category_id)
                .then(function (response) {
                    commit('DELETE_CATEGORY', category);
                    resolve(state.all);
                })
                .catch(function (response) {
                    alert('sssss');
                });

        })
    },
    saveCategory ({commit, dispatch, getters}, category) {
        return new Promise((resolve, reject) => {
            if (typeof (category.id) == "undefined"){
                axios.post('/api/categories', category)
                    .then(function (resp) {
                        commit('ADD_CATEGORY', category);
                    })
                    .catch(function (resp) {
                        alert("Не удалось создать категорию");
                    });
            } else {
                axios.put('/api/categories/' + category.id, category)
                    .then(function (resp) {
                        ;
                    })
                    .catch(function (resp) {
                        alert("Не удалось создать категорию");
                    });
            }

        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
