const state = {
    user: {}
};

const mutations = {
    SET_USER( state, user){
        state.user = user;
    }
};


const getters = {
    getCurrentUser: state => {
        return state.user;
    },
    isEmptyUser: state => {
        for(let key in state.user)
        {
            return false;
        }
        return true;
    }
};

const actions = {
    setUser ({commit, dispatch, getters}, user) {
        commit('SET_USER', user);
    },

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}