<template>
        <v-data-table
            :headers="headers"
            :items="users"
            :options.sync="options"
            :server-items-length="totalusers"
            :loading="loading"
            class="elevation-1"
        >
            <template v-slot:item.time_points="{ item }">
                    {{item.time_points|moment('from') }}
            </template>
        </v-data-table>
</template>

<script>
export default {
    data () {
        return {
            totalusers: 0,
            users: [],
            loading: true,
            options: {},
            headers: [
                {
                    text: 'Пользователь',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'очков', value: 'points' },
                { text: 'Последняя активность', value: 'time_points' },

            ],
        }
    },
    watch: {
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    mounted () {
        this.getDataFromApi()
    },
    methods: {
        getDataFromApi () {
            this.loading = true
            this.fakeApiCall().then(data => {
                this.users = data.data.data
                this.totalusers = data.data.total
                this.loading = false
            })
        },
        /**
         * In a real application this would be a call to fetch() or axios.get()
         */
        fakeApiCall () {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                let items = axios.get('rating/load', {params: {
                        page: page,
                        limit: itemsPerPage,
                        sort: sortBy,
                        desc: sortDesc,
                    }})
                    .then(response => {
                        resolve(response)
                        this.loading = false
                    }
                );
                //const total = items.length
            })
        },
    },
}
</script>
<style scoped>

</style>
