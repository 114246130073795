<template>
    <v-row>
      <v-col cols="12" sm="12">
        Данные для проверки
        <v-icon @click.prevent="addData" v-if="isEdit" aria-label="Добавить"  aria-hidden="false">
          mdi-pencil-plus
        </v-icon>
      </v-col>
        <v-col cols="12" sm="12" v-for="(item, index) in value" key="item.id">
          <v-row>
            <v-col cols="5">
              <v-textarea v-model="item.in_data" v-if="isEdit"></v-textarea>
              <div v-else>{{item.in_data}}</div>
            </v-col>
            <v-col cols="5">
              <v-textarea v-model="item.out_data" v-if="isEdit"></v-textarea>
              <div v-else>{{item.out_data}}</div>
            </v-col>
            <v-col cols="2">
              <v-icon @click.prevent="remove(index)" v-if="isEdit" aria-label="Удалить"  aria-hidden="false">
                mdi-delete
              </v-icon>
            </v-col>
          </v-row>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "TaskDataComponent",
        props: {
            value: {type: Array},
            isEdit: false
        },
        data: function () {
            return {task_data: []}
        },
        mounted(){
            this.task_data = this.value;
        },
        methods:{
            addData(){
                this.value.push({in_data: null, out_data: null})
            },
            remove(index){
                this.value.splice(index,1);
            }
        }
    }
</script>
<style scoped>

</style>
