import taskService from '../../services/tasks'

const state = {
    task: {
        id: null
    },
    users: []
};

const mutations = {
    SET_TASK( state, task ){
        state.task = task;
    },
    /*SET_USERS( state, users ){
        state.users = users;

    },
    DELETE_USER(state, user){
        let index = state.users.findIndex((item)=>{
            return item.id == user.id;
        });
        state.users.splice(index, 1);
    }*/
};

const getters = {
};

const actions = {
    loadTask({commit, state}, taskId){
        return new Promise( resolve => {
            if (taskId == state.task.id){
                resolve(state.task)
            }else{
                taskService.loadTask(taskId).then( data => {
                    commit('SET_TASK', data)
                    resolve(data)
                })
            }
        })
    },
    /*storeCourse ({commit, dispatch, getters}, course){
        commit('SET_COURSE', course);
    },*/
    saveTask({commit, state}, task){
        return new Promise(resolve => {
            taskService.saveTask(task).then( data => {
                commit('SET_TASK', data)
                resolve(data)
            }).catch(error=>{
                reject(error)
            })
        })
    },
    /*getUsersByCourse({commit, dispatch, getters}, par) {
        return new Promise(resolve =>{
            courseService.getUsersByCourse(par).then( data => {
                commit('SET_USERS', data.data.data)
                    resolve(data.data)
                })
        });
    },
    deleteUser({commit, dispatch, getters}, params){
        new Promise(resolve => {
            courseService.deleteUser(params).then( data => {
                commit('DELETE_USER', params.user)
                resolve(state.users)
            })
        })
    }*/

};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
