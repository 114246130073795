<template>
    <div>
        <li class = "nav-item">
            <div  @click="onClick">
                {{ item.title }}
                <span v-if="isFolder">[{{ isOpen ? '-' : '+' }}]</span>
            </div>
            <ul v-show="isOpen" v-if="isFolder" class="navbar-nav">
                <tree
                        v-for="(child, index) in item.children"
                        :key="index"
                        :item="child"
                        @fetch-data="$emit('fetch-data', $event)"
                        @selected="$emit('selected', $event)"
                ></tree>
            </ul>
        </li>
    </div>
</template>
<script>
    //import {mapActions} from 'vuex'

    export default {
        // define the tree-item component
        name: "tree",
        props: {
            item: Object
        },
        data() {
            return {
                // isOpen: false,
                opened: null,
                selectId: 12,
            }
        },
        created() {
            //this.isOpen = this.haveId(this.item, 12);
        },
        computed: {
            isFolder: function () {
                return this.item.children &&
                    this.item.children.length
            },
            isOpen: function(){
                return (this.opened == null && this.haveId(this.item, 12)) || this.opened;
            }
        },
        methods: {
            /*
            ...mapActions('tree', {
                setSelected: 'setSelected'
            }),*/
            haveId: function(item, id){
                if (item.id == id ) return true;

                return item.children && item.children.findIndex(
                    function(x){
                        return this.haveId(x, id)
                    },
                    this
                ) != -1;
            },
            setSelected: function(item){
                this.$emit('selected', item);
            },
            onClick: function () {
                this.setSelected(this.item);
                if (this.isFolder) {
                    this.opened = !this.opened
                }else{
                    this.$emit('fetch-data', this)
                }
            },
        }
    }
</script>
