import Vue from 'vue'
import Vuex from 'vuex'
import files from './modules/files'
import comments from "./modules/comments";
import profile from "./modules/profile";
import courseStore from "./modules/courseStore";
import taskStore from "./modules/taskStore";
import articleStore from "./modules/articleStore";
import users from "./modules/users";
import categories from './modules/categories'
import messages from "./modules/messages";
Vue.use(Vuex);

export default new Vuex.Store({
    modules: {
        files,
        comments,
        profile,
        users,
        categories,
        messages,
        courseStore,
        taskStore,
        articleStore,
    }
})
