<template>
    <div>
        <!-- <div>Files</div> -->
        <v-list two-line>
            <v-list-item-group
                color="primary"
                multiple
            >
                <template v-for="(file, index) in files">
                    <v-list-item :key="file.id">
                        <template v-slot:default="{ active }">
                            <v-list-item-content>
                                <v-list-item-subtitle
                                    v-text="file.caption"
                                ></v-list-item-subtitle>
                                <component :is="fileViewComponent" v-bind:value="file"></component>
                                <v-text-field v-model="file.caption" label="Caption" v-if="!readOnly"></v-text-field>

                                <v-list-item-subtitle
                                    v-text="file.name"
                                    v-if="!readOnly"
                                ></v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                                <v-list-item-action-text v-text="file.action"></v-list-item-action-text>

                                <v-icon
                                    color="dark darken-3"
                                    @click.prevent ="removeFile({model:model, file: file})"
                                    v-if="!readOnly"
                                >
                                    mdi-delete
                                </v-icon>
                            </v-list-item-action>
                        </template>
                    </v-list-item>

                    <v-divider
                        v-if="index < files.length - 1"
                        :key="index"
                    ></v-divider>
                </template>
            </v-list-item-group>
        </v-list>
    </div>
</template>
<script>
    import {mapActions, mapState, mapGetters} from 'vuex'
    export default {
        name: "FileListComponent",
        props:{
            readOnly:
            {
                type: Boolean,
                default: true
            },
            model: {
                type: String,
                required: true
            },
            showFileNames: {
                type: Boolean,
                default: true,
            },
            fileViewComponent: {
                type: Object,
                required: true
            }
        },
        data(){
            return {
            }
        },
        methods: {
            ...mapActions('files', ['removeFile']),
        },
        computed: {
            ...mapGetters('files', ['getFiles']),
            files(){
                return this.getFiles(this.model)
            },
        },

    }
</script>

<style scoped>
    .child
    {
        padding: 10px;
        display: inline-block;
    }
</style>
