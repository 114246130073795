<template>
    <div>
        <div class="black" ref="black"><div class="spinner"></div></div>
        <v-card>
            <v-card-text>
                <v-select
                    label="Выберите язык"
                    item-text="name"
                    item-value="id"
                    v-model="currentLanguage"
                    :items="languages">
                </v-select>
                <v-alert type="warning" v-if="task_solve.status.name == 'on_check'">
                    Ваше {{task_solve.status.description}}. После проверки вам поступит уведомление.
                </v-alert>
                <pre><code v-if="task_solve.status.name == 'on_check'">{{task_solve.error_message}}</code></pre>
                <v-alert type="error" v-if="this.task_solve.status.name == 'fail'">
                    При проверке решения произошла ошибка.
                </v-alert>
                <pre><code v-if="task_solve.status.name == 'fail'">{{task_solve.error_message}}</code></pre>
                <v-alert type="success" v-if="task_solve.status.name == 'success'">
                    Поздравляем, Ваше решение успешно прошло проверку.
                </v-alert>
                <strong>Решение</strong>
                    <codemirror v-model="task_solve.solve" :options="cmOptions" class="code"></codemirror>
                </v-card-text>
                <v-card-actions>
                    <v-btn @click = "save" text>
                        Отправить
                    </v-btn>
                </v-card-actions>
            </v-card>
    </div>
</template>

<script>
    import { codemirror } from 'vue-codemirror';
    import 'codemirror/lib/codemirror.css';

    // import language js
    import 'codemirror/mode/javascript/javascript.js';
    import 'codemirror/mode/python/python.js';
    import 'codemirror/mode/php/php.js';
    import 'codemirror/mode/sql/sql.js';

    // theme css
    import 'codemirror/theme/monokai.css';

    import languages from "../../store/modules/languages";

    export default {
        name: 'TasksSolveComponent',
        components: {
            codemirror
        },
        props:{
            dataTask:{
                type: String,
                //default: ()=>{ return null}
            }
        },
        data: function () {
            return {
                isExecuteQuery: 0,
                task_solve_default: 'type your code here...',
                solves: [],
                task_solve:
                    {
                        task_id: null,
                        solve: "",
                        error_message:null,
                        language:{
                            id: null
                        },
                        status: {
                            name: null,
                            id: null
                        }
                    },
                default_task_solve: null,
                selected: null,
                currentLanguage: null,
                languages: [],
                task: {
                    id:null,
                }
            }
        },
        computed:{
              cmOptions() {
                 return {
                   tabSize: 4,
                   styleActiveLine: false,
                   lineNumbers: true,
                   styleSelectedText: false,
                   line: true,
                   matchBrackets: true,
                   theme: "monokai",
                   mode: this.hightlightMode(),
                 }
             }
        },
        methods: {
            hightlightMode(){
	        const lang = this.languages.filter( item => { return item.id == this.currentLanguage }).pop()
                switch(lang.name){
                    case 'php': return "text/x-php";
                    case 'python': return "text/x-python";
                    case 'cpp': return "text/x-c++src";
                    default:
                       return 'text/javascript';
                }
            },
            save() {
                let pr = new Promise((resolve, reject)=>{
                    this.isExecuteQuery = 1
                    this.hide();
                    let request = {language_id: this.task_solve.language.id, solve: this.task_solve.solve};
                    axios.post('/tasks/'+this.task.id+'/resolve', request)
                        .then((response)=>{
                            resolve(response);
                        })
                        .catch((error)=>{
                            reject(error);
                        })
                })
                pr.then((response)=>{
                    this.isExecuteQuery = 0
                    this.show();
                    if (response.data.errors){
                        this.errors = response.data.errors;
                    }
                    this.task_solve = response.data.task_solve;
                }).catch((reject)=>{
                    this.isExecuteQuery = 0
                    this.show();
                })
            },
            hide(){
                this.$refs.black.style['z-index'] = 1000;
            },
            show(){
                this.$refs.black.style['z-index'] = -1000;
            },
        },
        beforeCreate() {
        },
        created(){
            const data = JSON.parse(this.dataTask);
            this.default_task_solve = Object.assign({}, this.task_solve);
            this.languages = data.languages;
            this.solves = data.solves;
            this.currentLanguage = (data.solves && data.solves.length) ? data.solves[0].language.id  : this.languages[0].id
            if (data.task_id) this.task.id = data.task_id;
        },
        watch: {
            'currentLanguage': function (newVal, oldVal) {
                let changedSolve = false;
                if (newVal !== oldVal){
                    const newSolve = this.solves.filter( item => {
                        return newVal === item.language.id
                    }).pop()
                    if( !newSolve ){
                        this.task_solve = Object.assign({}, this.default_task_solve)
                        this.task_solve.language.id = newVal
                        this.solves.push(this.task_solve)
                    }else
                        this.task_solve = newSolve
                }
                /*
                this.task_solve= Object.assign({},  this.default_task_solve);
               */
            },
        }
    }
</script>
<style scoped>
.block{
    display:block  !important ;
}
.code {
    font-family: monospace;
}
.wrapper{
    position: relative;
}
.black{
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    z-index: -1000;
    background:rgba(0,0,0, 0.5);
}
.spinner{
    position: absolute;
    left: 0px;
    top: 0px;
    bottom: 0px;
    right: 0px;
    background-image: url(/images/ajax_loader_white.gif);
    background-position: center center;
    background-repeat: no-repeat;
}
</style>
