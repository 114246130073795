<template>
    <div>
        <div class="media">
            <div class="media-left mr-2">
                <img :src="node.profile.avatar_path" class="img-sm img-circle"/>
            </div>
            <div class="media-body">
                <div>
                    <div class="left"><a :href="'/profiles\/show/' + node.profile.id" class="btn-link">{{node.profile.name}}</a></div><!-- <div class="right"><span class="text-muted text-sm"><i class="fa fa-mobile fa-lg"></i> </span></div> -->
                    <div class="right"><small class="muted">{{ node.updated_at | moment("from") }}</small></div>
                </div>
                <p class="komen">{{ node.content }}</p>
                <comments-add-component :node="node" :model-id="modelId" :model-name="modelName"
                    @add-comment="add($event)"></comments-add-component>
                <hr>
            </div>
        </div>
        <div v-if="node.children && node.children.length" class="geser">
            <comments-component v-for="child in node.children" :node="child" :model-id="modelId" :model-name = "modelName" :key="child.id"></comments-component>
        </div>
    </div>
</template>

<script>
    import CommentsAddComponent from "./CommentsAddComponent";
    import {mapGetters} from 'vuex'
    import ImageViewComponent from "../files/ImageViewComponent";
    import moment from 'moment';

    export default {
        name: "CommentsComponent",
        components: {ImageViewComponent, CommentsAddComponent},
        props: {
            node: Object,
            modelName: String,
            modelId: Number
        },
        methods:{
            add: function(comment) {
                //this.node.children.push(comment);
            }
        },
        computed:{
            ...mapGetters('users', ['getCurrentUser']),
        }
    }
</script>
<style scoped>
    .title {
        font-size: 14px;
        font-weight:bold;
    }
    .komen {
        font-size:14px;
    }
    .geser {
        margin-left:15px;
        margin-top:0.3rem;
    }
    .img-sm {
        width: 46px;
        height: 46px;
    }
    .img-circle{
        border-radius: 23px;
    }
    p{
        margin-bottom: 0.02rem;
    }
    hr{
        margin: 0.03rem;
    }

    .left, .right {
        display: inline-block;
        width: 49%;
        /*margin-right: -100%;
        vertical-align: bottom;*/
    }
    .right {
        text-align: right;
    }
    /*.left a, .right a { display: inline-block; position: relative; }
    .left a { width: 200px; height: 100px; background: green; }
    .right a { width: 100px; height: 200px; background: pink; }
    */

</style>
