<template>
    <div>
    </div>
</template>
<script>
    import {mapActions} from 'vuex'
    export default {
        props:{
            dataUser:{
                type: String,
            }
        },
        name: "UsersComponent",
        methods:{
            ...mapActions('users',['setUser'])
        },
        created(){
            this.setUser(JSON.parse(this.dataUser));
        }
    }
</script>

<style scoped>

</style>