<template>
    <div>
        <v-card>
            <v-toolbar
                flat
                class="primary"
            >
                <v-toolbar-title  class="white--text">Личный кабинет</v-toolbar-title>
            </v-toolbar>
            <v-tabs vertical>
                <v-tab v-for="route in $router.options.routes" v-if="route.title" :key="route.path">
                    <v-icon left>
                        {{route.icon}}
                    </v-icon>
                    <router-link :to="route.path">{{route.title}}</router-link>
                </v-tab>
                <v-tabs-items>
                    <v-card>
                        <router-view :user="user"></router-view>
                    </v-card>
                </v-tabs-items>
            </v-tabs>
        </v-card>
    </div>
</template>

<script>
    import profileComponent from './ProfileComponent';
    import attemptsComponent from './AttemptsComponent';
    import passwordComponent from './ChangePasswordComponent';
    import notificationComponent from './NotificationComponent'
    import MessagerComponent from './MessagerComponent'
    import VueRouter from 'vue-router';
    import Vue from 'vue';

    Vue.use(VueRouter);

    const router = new VueRouter({
            mode: 'history',
            base: '/cabinet/',
            routes: [
                {path: '*', redirect: '/profile'},
                {path: '/profile', component: profileComponent, title: 'Профиль', icon: 'mdi-account'},
                {path: '/attempts', component: attemptsComponent, title: "Сертификаты" , icon: 'mdi-certificate'},
                {path: '/notifications', component: notificationComponent, title: 'Уведомления', icon: 'mdi-bell-ring'},
                {path: '/password', component: passwordComponent, title: "Сменить пароль", icon: 'mdi-lock'},
                {path: '/messager/:user_id?', component: MessagerComponent, title: "Сообщения", props: (route) => ({user_id: Number(route.params.user_id)}), icon:'mdi-forum'},
            ]
        }
    );

    export default {
        name: "CabinetComponent",
        router: router,
        props:{
            dataUser:{
                type: String,
            },
        },
        data: function () {
            return {
                user: {user_id: null, profile_id:null}
            }
        },
        created(){
            this.user = JSON.parse(this.dataUser);
        }
    }
</script>

<style scoped>
    .tab {
        color: darkslategrey;
    }
</style>
