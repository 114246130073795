// src/plugins/vuetify.js

import Vue from 'vue'
import Vuetify from 'vuetify'
import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loader
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify)

const opts = {  
    icons: {
        iconfont: 'mdi', // default - only for display purposes
    },
    silent: true,
    theme: {
        themes: {
            light: {
                primary: colors.blueGrey,
                secondary: colors.grey.darken1,
                accent: colors.shades.black,
                error: colors.red.accent3,
            },
            dark: {
                primary: colors.blueGrey.darken2,
                secondary: colors.blueGrey.lighten2,
                accent: colors.blueGrey.darken3,
            },
        },
    },
}

export default new Vuetify(opts)
