const state ={
    comments:[]
};

const getters ={
    getComments: ()=> {
        return state.comments;
    },
    /*getParentElement: function(elem, comment){
        let parent_id = comment.parent_id
        if (elem.id == parent_id){
            return elem;
        } else {
            for (let i = 0; i < elem.children.length; i++){
                return getters.getParentElement(elem.children[i], comment)
            }
        }
    },
    searchParent: function(comment) {
        for (let i = 0; i < state.comments.length; i++){
            let p = getters.getParentElement(state.comments[i], comment);
            return p;
        }
    }*/
    getParentElement: function(arr, comment){
        let parent_id = comment.parent_id
        for (let i = 0; i < arr.length; i++){
            if (arr[i].id == parent_id){
                return arr[i];
            }

            if (arr[i].children && arr[i].children.length){
                let child = arr[i].children.length

                let parent = getters.getParentElement(arr[i].children, comment)
                if (parent)
                    return parent
            }
        }
        return null
    }

};

const mutations ={
    SET_COMMENTS: function (state, comments) {
        state.comments = comments;
    },
    ADD_COMMENT: function (state, comment) {
        if (comment.parent_id == null){
            state.comments.push(comment)
        } else {
            let parent = getters.getParentElement(state.comments, comment);
                if (!parent.children){
                    parent.children = [];
                }
                parent.children.push(comment);
        }
    }
};

const actions ={
    addComment ({commit, dispatch, getters}, comment) {
        return new Promise((resolve, reject) => {
            axios.post('/api/comments', comment).
            then(response => {
                commit('ADD_COMMENT', response.data.data[0]);
                resolve(response.data.data[0])
            }, response => {
                ;
            });
        })
    },
    loadComments({commit, dispatch, getters}, model){
        return new Promise((resolve, reject)=>{
            axios.post('/api/comments/search', model).
            then(response => {
                commit('SET_COMMENTS', response.data.data)
                resolve(response.data.data);
            },
            response => {
                ;
            })
        })
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
