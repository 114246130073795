import {reject} from "lodash/collection";

let state = {languages:[]}
let getters = {}
let mutations = {
    'SET_LANGUAGES': (state, languages)=>{
        state.languages = languages;
    }
}

let actions = {
    load: function({commit, dispatch}){
        return new Promise((resolve, reject)=>{
            axios('/api/languages')
                .then((responses)=>{
                    commit('SET_LANGUAGES', responses.data)
                    resolve(responses.data)
                })
                .catch((error)=>{
                    reject(JSON.parse(error.response.request.response))
                })
        })
    }
}

export default {
    namespaced: true,
    actions,
    state,
    mutations,
    getters

}
