<template>
    <v-card>
        <v-card-title>Список студентов</v-card-title>
        <v-card-text>
            <course-add-student :course_id="course_id" @refresh-table = 'refresh()'></course-add-student>
            <v-data-table
                :headers="headers"
                :items="users"
                :options.sync="options"
                :server-items-length="totalusers"
                :loading="loading"
                class="mt-4"
            >
                <template v-slot:item.points="{ item }"><a @click="deleteItem(item)">Удалить</a></template>
            </v-data-table>
        </v-card-text>
    </v-card>
</template>

<script>
import {mapActions} from 'vuex'
import CourseAddStudent from './CourseAddStudentComponent'
export default {
    name: "StudentsComponent",
    props: {
        course_id: {
            type: [Number, String]
        }
    },
    data () {
        return {
            totalusers: 0,
            users: [],
            loading: true,
            options: {},
            headers: [
                {
                    text: 'Пользователь',
                    align: 'start',
                    sortable: false,
                    value: 'name',
                },
                { text: 'Действия', value: 'points' },

            ],
        }
    },
    components: {
        CourseAddStudent
    },
    watch: {
        options: {
            handler () {
                this.getDataFromApi()
            },
            deep: true,
        },
    },
    mounted () {
        this.getDataFromApi()
    },
    methods: {
        ...mapActions('courseStore', ['getUsersByCourse', 'deleteUser']),
        refresh(){
            this.getDataFromApi()
        },
        deleteItem(item){
            this.deleteUser({user: item, course:this.course_id}).then((response)=>{

            })
        },
        getDataFromApi () {
            this.loading = true
            this.getUsersForCourse().then(response => {
                this.users = response.data
                this.totalusers = response.total
                this.loading = false
            })
        },
        getUsersForCourse () {
            return new Promise((resolve, reject) => {
                const { sortBy, sortDesc, page, itemsPerPage } = this.options

                let params = {
                    course_id: this.course_id,
                    params: {
                        page: page,
                        limit: itemsPerPage,
                        sort: sortBy,
                        desc: sortDesc,
                    }};

                let items = this.getUsersByCourse(params)
                    .then(response => {
                        resolve(response)
                        this.loading = false
                    })
            })
        },
    },
}
</script>

<style scoped>
</style>
