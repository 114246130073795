const state = {
    all: [],
    genders: []
};

const mutations = {
    SET_PROFILE( state, profile ){
        state.all = profile;
    },
    SET_GENDERS( state, genders ){
        state.genders = genders;
    },
    /*ADD_CATEGORY( state, category ){
        state.all.push(category);
    },
    DELETE_CATEGORY( state, category){
        let index = getters.getIndexByID(state)(category.id);
        state.all.splice(index, 1);
    }*/
};

const getters = {
    /*findByParent: state => id => {
        let filtered = state.all.filter( item => {
            return item.parentid == id
        });
        return filtered;
    },
    findById: state => id => {
        let filtered = state.all.find(item => {
            return item.id == id
        });
        return filtered;
    },
    getRootCategory: (state, getters) => {
        return getters.findById(1) || {};
    },
    getCategories: state => {
        return state.all
    },
    getIndexByID: state => id => {
        let index = state.all.findIndex(item => {
            return item.id == id
        });
        return index;
    },
    getCategoriesWithOut: state => without => {
        return state.all.filter(item => !without.includes(item));
    }
    */
};

const actions = {
    loadProfile ({commit, dispatch, getters}, profile) {
        return new Promise((resolve, reject) => {
            axios.get('/profiles/'+profile).
            then(response => {
                commit('SET_PROFILE', response.data.profile);
                commit('SET_GENDERS', response.data.genders);
                resolve(response.data)
            }, response => {
                ;
            });
        })
    },
    saveProfile ({commit, dispatch, getters}, profile) {
        return new Promise((resolve, reject) => {
            if (profile.id == null){
                axios.post('/profiles', profile)
                    .then(function (response) {
                        commit('SET_PROFILE', response.data.profile);
                        resolve(response.data)
                    })
                    .catch(function (response) {
                        alert("Не удалось создать профиль");
                    });
            } else {
                axios.put('/profiles/' + profile.id, profile)
                    .then(function (response) {
                        resolve(response.data);
                    })
                    .catch((error) => {
                        let response = JSON.parse(error.response.request.response);
                        reject(response);
                    });
            }
        })
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}