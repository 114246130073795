const state = {
    files: []
//    all: new Map()
};

const mutations = {
    SET_FILES(state, {model, files}){
        // Из-за этого зацикливание!

        function addFile(item) {
            let index = state.files.findIndex( f => {
                return item.id === f.id
            });
            if( index === -1 ) {
                item.model = model;
                state.files.push(item)
            }
        }

        state.files.forEach(
            function(item, index, array){
                if( item.model === model ){
                    let index = - 1
                    if( files ){
                        index = files.findIndex( f => {
                           return item.id === f.id
                        });
                    }
                    if( index === -1 ) {
                        array.splice(index, 1)
                    }
                }
            }
        );

        if(files instanceof Array) {
            files.forEach((item)=>{
                addFile(item)
            });
        } else {
            addFile(files)
        }
    },
    APPEND_FILE(state, {model, file, multiple}){
        if (!multiple){
            state.files.forEach(
                function(item, index, array){
                    if( item.model === model){
                        array.splice(index, 1)
                    }
                }
            );
        }

        file.model = model;
        state.files.push(file)
    },
    DELETE_FILE(state, {model, file}){
        let index = getters.getIndexByID(state)(model, file);
        state.files.splice(index, 1);
    },
    RENAME_MODEL_FILE(state, {model_new, model_old}){
        state.files.filter( item => {
            if (item.model === model_old){
                item.model = model_new;
            }
        })
    },
};


const getters = {
    getFiles: (state) => (model) => {
        return state.files.filter( item => {
            return item.model === model
        })
    },
    getIndexByID: state => (model, file) => {
        let index = state.files.findIndex(item => {
            return item.id === file.id && item.model === model
        });
        return index;
    },
    getState:()=>{
        return state;
    }
};

const actions = {
    loadFile({commit, dispatch, getters}, {model, file}){
        return new Promise((resolve, reject)=>{
            axios.get( '/file/' + file.id)
                .then(function(response){
                    commit('APPEND_FILE', model, response.data);
                    resolve(response.data)
                })
                .catch(function(err){
                    ;
                });
        });
    },
    loadFiles({commit, dispatch, getters}, props){
       return new Promise((resolve, reject)=>{
            axios.post( '/'+props.pathSave,
                props.formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function(response){
                let files = response.data.map((file)=>{ return file.attaches });
                let model=props.formData.get('model')
                let multiple = props.multiple
                files.forEach((file) => {
                    commit('APPEND_FILE', {model, file, multiple});
                } );
                resolve(response.data)
            }).catch(function(error){
                reject(error)
            });
        });
    },
    renameModelFiles({commit, dispatch, getters},{model_new, model_old}){
        commit('RENAME_MODEL_FILE', {model_new, model_old});
    },
    removeFile({commit, dispatch, getters}, {model, file}){
        commit('DELETE_FILE', {model, file});
    },
    addFile({commit, dispatch, getters}, {model, file, multiple}){
        commit('APPEND_FILE', {model, file, multiple});
    },
    setFiles({commit, dispatch, getters}, {model, files}){
        commit('SET_FILES', {model, files});
    }
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
