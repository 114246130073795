<template>
    <div id="countdown" class="countdown">
        <span class="countdown-time">{{ hours }}</span>
        <span class="countdown-time">{{ minutes }}</span>
        <span class="countdown-time">{{ seconds }}</span>
    </div>
</template>

<script>
    export default {
        name: 'TimerComponent',
        props:{ delta: Number},
        data() {
            return {
                timer: null,
                hours: '00',
                minutes: '00',
                seconds: '00',

            }
        },
        mounted() {
            this.startTimer()
        },
        destroyed() {
            this.stopTimer()
        },
        created(){
            this.finishTime = new Date().getTime() + this.delta;
        },
        methods: {
            startTimer() {
                this.timer = setInterval(() => {
                    let diff = this.finishTime - new Date().getTime();

                    if (diff <= 0){
                        this.stopTimer()
                        this.hours = this.minutes = this.seconds = '00'
                    }
                    else {
                        let tdays = Math.floor(diff / (1000 * 60 * 60 * 24));
                        let thours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                        let tminutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
                        let tseconds = Math.floor((diff % (1000 * 60)) / 1000);


                        this.hours = (thours < 10) ? '0' + thours : thours;
                        this.minutes = (tminutes < 10) ? '0' + tminutes : tminutes;
                        this.seconds = (tseconds < 10) ? '0' + tseconds : tseconds;
                    }
                }, 1000)
            },
            stopTimer() {
                clearTimeout(this.timer)
                this.$emit('timer-finish');
            },
        },
        watch: {
            finishTime: function (time) {
                if (time <= 0) {
                    this.stopTimer()
                }
            }
        }
    }
</script>
<style scoped>

</style>
