<template>
    <div>
        <v-card>
            <v-card-title>УВЕДОМЛЕНИЯ</v-card-title>
            <v-card-text>
                <v-tabs>
                    <v-tab v-for="item in items" :key="item.tab" @click="showListItems(item)">{{ item.tab }}</v-tab>
                </v-tabs>
                <v-list>
                    <v-list-group v-for="item in notifications" :key="item.id" no-action v-if="showAll || !item.read_at || item.changed" :color= "item.read_at ? 'primary' : 'success'">
                        <template v-slot:activator>
                            <v-list-item  @click="showNotification(item)">
                                <v-list-item-content>
                                    <v-list-item-title>{{item.data.title}}</v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <v-list-item>
                            <v-list-item-content>
                                <v-card id="card-1" outlined shaped>
                                    <v-card-text class="py-1"><p class="my-1" v-html="item.data.message" style="white-space: normal;"></p></v-card-text>
                                    <v-card-subtitle class="py-1">{{item.created_at| moment('from')}}</v-card-subtitle>
                                </v-card>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                </v-list>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
    export default {
        name: "NotificationComponent",
        data:function(){
            return {
                notifications: [],
                notificationShow: null,
                showAll: true,
                items: [
                    { tab: 'Все', type: 'all' },
                    { tab: 'Непрочитанные', type: 'unread' },
                ]
            }
        },
        created(){
            axios.get('/notification').
            then((response) => {
                this.notifications = response.data.notifications;
            }).
            catch((error) => {
                this.errors = JSON.parse(error.response.request.response)
            })
        },
        methods:{
            showListItems(item, type){
                this.showAll = item.type == 'all' ? true: false;
            },
            showNotification(item){
                if (item.read_at == null){
                    axios.post('/notification/mark', {id: item.id}).
                    then((response)=>{
                        item.read_at = true;
                        item.changed = true;
                        this.showAll = false;
                    }).
                    catch((error)=>{
                    })
                }
            }
        }
    }
</script>

<style scoped>
</style>
