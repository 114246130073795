var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v("\n        Редактирование задачи\n    ")]),_vm._v(" "),_c('v-card-text',[(_vm.course_id==null)?_c('div',[_c('v-select',{attrs:{"items":_vm.categories,"label":"Категория","item-text":"title","item-value":"id"},model:{value:(_vm.task.info.category_id),callback:function ($$v) {_vm.$set(_vm.task.info, "category_id", $$v)},expression:"task.info.category_id"}})],1):_vm._e(),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Название","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Название","error-messages":errors},model:{value:(_vm.task.info.title),callback:function ($$v) {_vm.$set(_vm.task.info, "title", $$v)},expression:"task.info.title"}})]}}])}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Описание","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Описание","error-messages":errors},model:{value:(_vm.task.info.description),callback:function ($$v) {_vm.$set(_vm.task.info, "description", $$v)},expression:"task.info.description"}})]}}])}),_vm._v(" "),_c('v-checkbox',{attrs:{"label":"Опубликовать","error-messages":_vm.errors},model:{value:(_vm.task.info.published),callback:function ($$v) {_vm.$set(_vm.task.info, "published", $$v)},expression:"task.info.published"}}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Тип задачи","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Тип задачи","error-messages":errors},model:{value:(_vm.task.type),callback:function ($$v) {_vm.$set(_vm.task, "type", $$v)},expression:"task.type"}})]}}])}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Содержимое","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Содержимое","error-messages":errors},model:{value:(_vm.task.content),callback:function ($$v) {_vm.$set(_vm.task, "content", $$v)},expression:"task.content"}})]}}])}),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Решение по умолчанию","rules":"required|max:255"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"label":"Решение по умолчанию","error-messages":errors},model:{value:(_vm.task.template),callback:function ($$v) {_vm.$set(_vm.task, "template", $$v)},expression:"task.template"}})]}}])}),_vm._v("\n        Уровень сложности\n        "),_c('v-radio-group',{model:{value:(_vm.task.level),callback:function ($$v) {_vm.$set(_vm.task, "level", $$v)},expression:"task.level"}},_vm._l((5),function(n){return _c('v-radio',{key:n,attrs:{"label":("" + n),"value":n}})}),1),_vm._v(" "),_c('validation-provider',{attrs:{"name":"Количество баллов","rules":"required|max:100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Количество баллов","error-messages":errors},model:{value:(_vm.task.points),callback:function ($$v) {_vm.$set(_vm.task, "points", $$v)},expression:"task.points"}})]}}])}),_vm._v(" "),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.save()}}},[_vm._v("Сохранить")]),_vm._v(" "),_c('v-btn',{on:{"click":function($event){return _vm.cancel()}}},[_vm._v("Отменить")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }