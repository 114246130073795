<template>
    <div>
        <v-autocomplete
            v-model="users_id"
            :loading="loading"
            :items="users"
            chips
            :search-input.sync="search"
            label="Введите email"
            item-text="email"
            item-value="id"
            multiple
        >
            <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="remove(data.item)"
                >
                    <v-avatar left>
                        <v-img :src="data.item.avatar"></v-img>
                    </v-avatar>
                    {{ data.item.name }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <template>
                    <v-list-item-avatar>
                        <img :src="data.item.avatar">
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title v-html="data.item.email"></v-list-item-title>
                        <v-list-item-subtitle v-html="data.item.group"></v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </template>
        </v-autocomplete>
        <v-btn color="primary" @click="save()">Добавить</v-btn>
    </div>
</template>

<script>
export default {
    name: "CourseAddStudentComponent",
    props:{
        course_id:{
            type: [Number, String]
        }
    },
    data () {
        return {
            loading: false,
            search: null,
            autoUpdate: true,
            users_id: [],
            users: [],
        }
    },

    watch: {
        search(val) {
            val && val !== this.users_id && this.getUsersByEmail(val);
        },
    },

    methods: {
        save(){
            axios.post('/course/'+this.course_id+'/users/', {users:this.users_id}).
            then(response => {
                this.users = response.data
                this.users_id = []
                this.$emit('refresh-table')
            }).catch(error => {
                this.$notify({
                    group: 'error',
                    title: '',
                    text: 'Не удалось добавить пользователя (пользователей) в список учеников'
                });
            }).finally(() => {
                this.loading = false
            });
        },
        remove (item) {
            const index = this.users_id.indexOf(item.id)
            if (index >= 0) this.users_id.splice(index, 1)
        },
        getUsersByEmail(val){
            this.loading = true
            axios.get(`/course/users/byemail/`+val).
            then(response => {
                //console.log("response", val, response.data)
                this.users = response.data
            }).catch(error => {
                this.$notify({
                    group: 'error',
                    title: '',
                    text: 'Пользователь с email ' +val+' не найден'
                });
            }).finally(() => {
                this.loading = false
            });
        }
    },
}
</script>

<style scoped>
</style>
