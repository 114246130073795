require('./bootstrap');

import Vue from 'vue'
import Vuex from 'vuex'

import vuetify from './plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
Vue.use(VueTelInputVuetify, {
    vuetify,
});

import store from './store/web'
import 'jquery-ui/ui/widgets/datepicker.js';
import Datepicker from 'vuejs-datepicker';
import $ from 'jquery';

import validate, {ValidationProvider} from 'vee-validate';
import { localize } from 'vee-validate';
import ru from 'vee-validate/dist/locale/ru.json';

localize('ru', ru);
import { extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/ru.json';

Object.keys(rules).forEach(rule => {
    extend(rule, {
        ...rules[rule], // copies rule configuration
        message: messages[rule] // assign message
    });
});

extend('phone',{
    message: 'Поле {_field_} должно содержать телефон',
    validate: value => {
        const reg_phone =/^((8|\+7)[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/g;
        return reg_phone.test(value);
    }
});

Vue.component('tasks-solve-component', require('./components/tasks/TasksSolveComponent.vue').default);
Vue.component('users-component', require('./components/users/UsersComponent.vue').default);
Vue.component('comments-list-component', require('./components/comments/CommentsListComponent.vue').default);
Vue.component('course-router-component', require('./components/courses/CourseRouterComponent.vue').default);
Vue.component('menu-component', require('./components/MenuComponent.vue').default);
Vue.component('timer-component', require('./components/TimerComponent.vue').default);
Vue.component('attempt-component', require('./components/AttemptComponent.vue').default);
Vue.component('files-component', require('./components/files/FilesComponent.vue').default);
Vue.component('cabinet-component', require('./components/cabinet/CabinetComponent.vue').default);
Vue.component('article-list-component', require('./components/articles/ArticleListComponent.vue').default);

Vue.component('raiting-component', require('./components/raiting/RaitingComponent.vue').default);

import infiniteScroll from 'vue-infinite-scroll'
import vue_moment from "vue-moment";
import Notifications from 'vue-notification'
Vue.use(Notifications)
Vue.use(infiniteScroll);

const moment = require('moment');
require('moment/locale/ru');

Vue.use(vue_moment, { moment } );


window.$ = window.jQuery = $;
window.Vue = require('vue');
window.axios = require('axios');
window.axios.defaults.headers.common = {
    'X-Requested-With': 'XMLHttpRequest',
    //'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
};

window.app = new Vue({
    store, 
    vuetify,
    components:{
        Datepicker,
        ValidationProvider
    },
});

app.$mount('#app');

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
});
