<template>
    <div><img :src="image" width="100%"/></div>
</template>

<script>
    export default {
        name: "FileViewComponent",
        props: ['value'],
        data(){
            return {
                image:null
            }
        },
        created(){
            axios.get('/images/'+this.value.attach_id)
            .then((response) => {
                this.image = "data:image/png;base64," + response.data.b64image
            }).catch((error) => {
                ;
            })
        }
    }
</script>

<style scoped>

</style>