<template>
    <v-row>
        <v-col cols="12">
            <v-textarea v-model="body" label="Введите сообщение"></v-textarea>
            <v-btn text @click="send()">отправить</v-btn>
        </v-col>
    </v-row>
</template>

<script>

import {mapActions} from "vuex";

export default {
    name: "MessageComponent",
    data: function (){
        return {
            body: ''
        }
    },
    props: {
        to_user_id: {
            type:Number,
        },
    },
    methods: {
        ...mapActions('messages',['addMessage']),
        send: function () {
            this.addMessage({to_user_id: this.to_user_id, body: this.body}).then((data)=>{
                this.body = '';
            });
            /*axios.post('/messages/send', {from_user_id: this.from_user_id, body: this.body})
                .then((responses)=>{
                    this.users = responses.data.data;
                })
                .catch((errors)=>{

                })*/
        }
    }
}
</script>

<style scoped>

</style>
