const courses = {
    loadCourse(course_id){
        return new Promise((resolve, reject) => {
            axios.get('/courses/load/'+course_id).
            then(response => {
                resolve(response.data.data)

            }).catch(function (errors) {
                reject(JSON.parse(errors.request.response));
            });
        });
    },
    saveCourse(course){
        return new Promise((resolve, reject) => {
            axios.put('/courses/'+course.id, course).
            then(response => {
                resolve(response.data.course)
            }).catch(function (errors) {
                reject(JSON.parse(errors.request.response));
            });
        })
    },
    getUsersByCourse(par){
        return new Promise((resolve, reject) => {
            axios.get(`/courses/${par.course_id}/students`, {params: par.params}).
            then(response => {
                resolve(response)
            }).catch(function (errors) {
                reject(JSON.parse(errors));
            });
        })
    },
    getUsersByEmail(val){
        return new Promise((resolve, reject) => {

        })
    },
    deleteUser(params){
        return new Promise((resolve, reject) => {
            axios.delete('/courses/'+params.course+'/users/'+params.user.id).
            then(response => {
                resolve(response)
            }).catch(function (errors) {
                reject(JSON.parse(errors));
            });
        })
    },
}

export default courses
