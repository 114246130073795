<template>
        <div>
            <comments-component v-for="(item, index) in commentList" :node="item"
                                     :model-id="modelId" :model-name = "modelName" :key="item.id">item-{{item}}</comments-component>
            <comments-add-component :node="{id:null}" :model-id="modelId" :model-name="modelName"></comments-add-component>
        </div>
</template>
<script>
    import {mapActions, mapGetters, mapState} from 'vuex'
    import commentsComponent from './CommentsComponent.vue'
    import commentsAddComponent from './CommentsAddComponent'
    export default {
        name: "CommentsListComponent",
        props:[
            'dataModelName',
            'dataModelId'
        ],
        created(){
            this.modelId = Number(this.dataModelId)
            this.modelName = this.dataModelName
            this.loadComments({model_id: this.modelId, model_name: this.modelName})
        },
        computed: {
            ...mapGetters('comments', ['getComments']),
            ...mapState('comments',['comments']),
            commentList(){
                return this.getComments
            }
        },
        watch: {
            comments: {
                handler(val){
                },
                deep: true
            }
        },
        methods:{
            ...mapActions('comments',['loadComments']),
        },
        components:{
            commentsComponent,
            commentsAddComponent
        }
    }
</script>

<style scoped>
    .title {
        /*font-size: 14px;*/
        font-weight:bold;
    }
    .komen {
        /*font-size:14px;*/
    }
    .geser {
        margin-left:15px;
        margin-top:5px;
    }

</style>
