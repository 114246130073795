<template>
    <div><img :src="image" width="100%" class="img-circle img-sm" :title="caption"/></div>
</template>

<script>
    export default {
        name: "AvatarViewComponent",
        props: ['value'],
        data(){
            return {
                image:null,
                caption:''
            }
        },
        created(){
            axios.get('/images/preview/'+this.value.attach_id)
                .then((response) => {
                    this.image = "data:image/png;base64," + response.data.b64image
                    this.caption = response.data.caption
                }).catch((error) => {
                    ;
                })
        }
    }
</script>

<style scoped>
    .img-sm {
        width: 80px;
        height: 80px;
    }
    .img-circle{
        border-radius: 40px;
    }
</style>